/**
 * This is used to make dom adjustments after the main dom (/app.js) has loaded.
 * Currently it is used to fix minHeight for mobile screens due to the URL bar.
 *
 */

import React from 'react';

const DomAdjustments = (props) => {

    React.useEffect(()=>{
        window.addEventListener('resize', handleScreenResize)
        handleScreenResize()
    }, [])

    const handleScreenResize = () => {
        if (document.getElementById('Main') !== null) {
            document.getElementById('Main').style.minHeight = `calc(${window.innerHeight}px - 52px)`
        }
    }

    return (<></>)
}

export default DomAdjustments;