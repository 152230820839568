import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import WorkIcon from '@material-ui/icons/Work';
import BuildIcon from '@material-ui/icons/Build';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import * as Actions from '../../store/actions';
import history from '../../history'
import Comments from '../comments';

export default function Footer (props) {

    const dispatch = useDispatch()
    const {comments, drawers} = useSelector(state=>state)

    const handleComments = () => {
        const is_returning = localStorage.getItem('is_returning')
        if (is_returning) {
            dispatch(Actions.openComments(!comments.show))
        } else {
            dispatch(Actions.openDescriptionAlert(true, 'Please select a terminal'))
        }
    }

    return (
        <div className="footer-bar">
            <div onClick={()=>history.push('/')}>
                <HomeIcon />
            </div>
            <div onClick={handleComments}>
                <ChatBubbleIcon />
            </div>
            <div onClick={()=>history.push('/jobs')}>
                <WorkIcon />
            </div>
            <div onClick={()=>history.push('/stations')}>
                <LocalGasStationIcon />
            </div>
            <div onClick={()=>history.push('/search')}>
                <SearchIcon />
            </div>
        </div>
    )
}