import React from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import history from '../../../history';

export const Page404 = (props) => {
    return (
        <div className="page">
            <div style={{position: 'relative', height: '100%'}}>
                <div style={{width: '100%', color: '#FFF'}}>
                    <div id="profile-arrow" className="page-content" style={{padding: '12px 8 0px'}}>
                        <ArrowBack onClick={()=>history.goBack()} />
                    </div>
                    <h2 style={{textAlign: "center"}}>Sorry this page doesn't exist</h2>
                </div>
            </div>
        </div>
    )
}