import { Build, LocalShipping, LocationCity, EmojiTransportation } from '@material-ui/icons';
import React from 'react';

export default function FilterIcon({ mapType, filter }) {

    const getIcon = () => {
        if (mapType === 'station') {
            return <EmojiTransportation />
        }
                        
        switch (filter) {
            case 'facilities':
                return <LocationCity />
            case 'repair_shops':
                return <Build />
            case 'truck_stops':
                return <LocalShipping />
            default:
                break;
        }
    }

    return (
        <>
            {getIcon()}
        </>
    )
}