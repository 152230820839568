
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as Actions from '../../store/actions';
import Locations from '../../utils/data/locations.json';
import Stations from '../../utils/data/stations.json';
import { Divider, FormControl, MenuItem, NativeSelect } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import history from '../../history.js';
import { ArrowDropDown } from '@material-ui/icons';
import { getCountryList, getStateList, getCountryName, getStateName } from '../../utils/names';
import CitiesList from './components/cities_list';
import CityItemsList from './components/city_items_list';

const useStyles = makeStyles((theme) => ({
    selectEmpty: {
        padding: 0,
        margin: 0,
        color: "#FFF",
        width: '100%',
        fontSize: 18
    },
    icon: {
        fill: '#FFF'
    }
}));
  
const CustomSelect = (props) => {

    const classes = useStyles()

    return (
        <NativeSelect
            disableUnderline
            value={props.value}
            onChange={props.onChange}
            name="age"
            inputProps={{ 'aria-label': 'age' }}
            classes={{
                icon: classes.icon
            }}
            className={classes.selectEmpty}
            style={props.style}
        >
            {props.children}
        </NativeSelect>
    )
}


const StationsComponent = (props) => {
    const {location, maps, stations} = useSelector(state => state)
    const [currCountry, setCurrCountry] = React.useState(location.country || "US")
    const [currState, setCurrState] = React.useState(location.state || "CA")
    const [currCity, setCurrCity] = React.useState(location.city)
    const mapFrom = process.env.NODE_ENV === 'development' ? Stations : stations
    const classes = useStyles()

    const dispatch = useDispatch()

    const setLocation = (country, state, city, id) => (e) => {
        dispatch(Actions.setUserCountryRegion(country))
        dispatch(Actions.setUserStateRegion(state))
        dispatch(Actions.setUserCityRegion(city))
        dispatch(Actions.setLocation(id))
        dispatch(Actions.setMapType('station'))
        localStorage.setItem('l_m', JSON.stringify({country: country, state: state, city: city, map: id, map_type: 'station'}))

        history.push('/')
    }

    return (
        <div className="page">
            <div id="search" className="page-content" style={{padding: 0, paddingTop: 12}}>
                <h1 style={{ margin: '12px 0', padding: '0 16px' }}>Stations</h1>
                <Divider />
                <CustomSelect
                    value={currCountry}
                    onChange={(e) => { setCurrCountry(e.target.value) }}
                    style={{fontSize: '1.3rem', margin: '8px 0', padding: '0 24px' }}
                >
                    {
                        Object.keys(getCountryList()).sort().map(country =>
                            <option value={country}>{getCountryName(country)}</option>
                        )
                    }
                </CustomSelect>
                <Divider />
                <CustomSelect
                    value={currState}
                    onChange={(e)=>{setCurrState(e.target.value)}}
                    style={{fontSize: '1.2rem', margin: '8px 0', padding: '0 24px' }}
                >
                    {
                        Object.keys(getStateList(currCountry)).sort().map(state =>
                            <option value={state}>{getStateName(currCountry, state)}</option>
                        )
                    }
                </CustomSelect>
                <Divider />
                {
                    mapFrom[currCountry] ?
                    <CitiesList
                        cities={mapFrom[currCountry][currState]}
                        currCity={currCity}
                        setCurrCity={setCurrCity}
                    >
                        <CityItemsList
                            cities={mapFrom[currCountry][currState]}
                            currCity={currCity}
                            setLocation={(id) =>{ setLocation(currCountry, currState, currCity, id)() }} />
                    </CitiesList>
                    :
                    <CitiesList
                        cities={getStateList(currCountry)}
                        useEmpty
                    />
                }
            </div>
        </div>
    )
}

export default StationsComponent;