
import React from 'react';
import { Divider, FormControl, makeStyles, MenuItem, NativeSelect } from '@material-ui/core';
import Locations from '../utils/data/locations.json';
import { useSelector } from 'react-redux';
import { getCountryName, getStateName } from '../utils/names';

const useStyles = makeStyles((theme) => ({
    selectDiv: {
        width: '49%',
        '& .MuiInput-underline:after': {
            borderBottomColor: '#2ebbb3',
        }
    },
    selectEmpty: {
        padding: 0,
        margin: 0,
        color: "#FFF",
        width: '100%',
        fontSize: 18
    },
    icon: {
        fill: '#FFF'
    }
}));

const Resources = (props) => {
    const [country, setCountry] = React.useState('US');
    const [state, setState] = React.useState(null)
    const {location, maps, firebase, resources} = useSelector(state => state)
    const mapFrom = process.env.NODE_ENV === 'development' ? Locations : maps    
    const classes = useStyles()

    React.useState(()=>{
        setCountry(location.country)
        setState(location.state)
        if (!resources[location.country] || !resources[location.country][location.state]) {
            firebase.retrieveResource({country: location.country, state: location.state}, ()=>{})
        }
    },[])

    const handleChange = (type) => (e) => {
        switch (type) {
            case 'country':
                setCountry(e.target.value)
                setState("null")
                break;
            case 'state':
                setState(e.target.value)
                if (e.target.value !== "null") {
                    if (!resources[country] || !resources[country][e.target.value]) {
                        firebase.retrieveResource({country: country, state: e.target.value}, ()=>{})
                    }
                }
                break;
            default:
                break;
        }
    }

    return (
        <div className="page">
            <div id="resources" className="page-content">
                <h2>Resources</h2>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div className={classes.selectDiv}>
                        <NativeSelect 
                        name="age"
                        inputProps={{ 'aria-label': 'age' }}
                        classes={{
                            icon: classes.icon
                        }}
                        className={classes.selectEmpty}
                        onChange={handleChange('country')}
                        value={country}
                        >
                        {
                            Object.keys(mapFrom).sort().map(ct =>
                                <option value={ct}>{getCountryName(ct)}</option>
                            )
                        }
                        </NativeSelect>
                    </div>
                    <div className={classes.selectDiv}>
                        <NativeSelect 
                        name="age"
                        inputProps={{ 'aria-label': 'age' }}
                        classes={{
                            icon: classes.icon
                        }}
                        className={classes.selectEmpty}
                        onChange={handleChange('state')}
                        value={state}
                        >
                        <option value={"null"}>Select Region</option>
                        {
                            Object.keys(mapFrom[country]).sort().map(st =>
                                <option value={st}>{getStateName(country, st)}</option>
                            )
                        }
                        </NativeSelect>
                    </div>
                </div>
                {   
                    resources[country] && resources[country][state] && resources[country][state].length > 0 ?
                    <ul style={{listStyleType: 'none', padding: 0, margin: 0, paddingTop: 16}}>
                    {
                        resources[country][state].map(rs => 
                            <li key={rs.title} style={{padding: '8px 0'}}>
                                <div><a href={rs.link} target="_blank"  style={{fontWeight: 'bold', color: "#FFF"}}>{rs.title}</a></div>
                            </li>
                        )
                    }
                    </ul>
                    :
                    state == null ?
                    <></>
                    :
                    <div style={{paddingTop: 16}}>
                        There are currently no resources for your region. Check back later!
                    </div>
                }
            </div>
        </div>
    )
}

export default Resources;