import React from 'react';

export const getFilterDescription = (mapType, filter) => {
    if (mapType === 'facility') {
      switch (filter) {
          case 'facilities':
              return 'You are on facility view mode. You can choose to view a different loading facility by clicking the search icon on the bottom right coner.'
              break;
          case 'repair_shops':
            return 'These are 24hr mobile truck repair services near this loading facility.'
            break;
          case 'truck_stops':
            return 'These are 24hr truck stops near this loading facility.'
            break;
          default:
            break;
        }
    }
  
  
    if (mapType === 'station') {
      return (
        <div>
          You are viewing a station.
          <div style={{marginTop: '1rem'}}>
            How to submit a route:
            <ol style={{marginTop: 8, marginBottom: 8}}>
              <li style={{marginBottom: 4}}>
                Click on the pencil icon and draw a route.
              </li>
              <li style={{marginBottom: 4}}>
                When route has been drawn "TAKE A SCREEN SHOT" on your phone.
              </li>
              <li style={{marginBottom: 4}}>
                Click the upload button and attach the screen shot of the route you drew, hit send.
              </li>
            </ol>
          </div>
        </div>
      )
    }
}