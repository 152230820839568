import * as Actions from '../../actions';

const initialState = {
    show: false,
    map: null,
    type: null,
};

const Station = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.SET_STATION_DRAWER:
        {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
        {
            return state;
        }
    }
};

export default Station;