import React from 'react';
import {Add, Build, LocationCity, LocalShipping} from '@material-ui/icons';

// (function() {
//     var speedDialContainer = document.querySelector(".speed-dial");
//     var primaryButton = speedDialContainer.querySelector(
//       ".speed-dial__button--primary"
//     );
  
//     document.addEventListener("click", function(e) {
//       var classList = "speed-dial";
//       var primaryButtonClicked =
//         e.target === primaryButton || primaryButton.contains(e.target);
//       var speedDialIsActive =
//         speedDialContainer.getAttribute("class").indexOf("speed-dial--active") !==
//         -1;
  
//       if (primaryButtonClicked && !speedDialIsActive) {
//         classList += " speed-dial--active";
//       }
  
//       speedDialContainer.setAttribute("class", classList);
//     });
//   })();
  
  

const SpeedDial = (props) => {

    const { open, handleOpen, icon, items, type } = props

    const handleDialClick = (cb) => (e) => {
        if (cb) {
            cb()
        }
    }

    return (
        <div className={`speed-dial${open ? ' speed-dial--active' : ''}`}>
        <button className="speed-dial__button speed-dial__button--primary" onClick={handleOpen} style={{opacity: 1}}>
          {icon}
        </button>
        <div className="speed-dial__options">
          {
            items.map((item, index) => 
                <button key={`speed-dial__button_${index}`} className="speed-dial__button" onClick={handleDialClick(item.onClick)}>
                    {item.icon}
                </button>
            )
          }
        </div>
      </div>
    )

}

export default SpeedDial