import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { TextField, InputAdornment } from '@material-ui/core';
import {Announcement, CheckCircleOutline, Close, SendOutlined} from '@material-ui/icons';
import * as Actions from '../../store/actions';
import moment from 'moment';
import Logo from '../../newlogo.png';

const placeholder = "Add Comment"

export default function Comments (props) {
    const dispatch = useDispatch()
    const [showAnnouncement, setShowAnnouncement] = React.useState(false);
    const {announcement, comments, firebase} = useSelector(state => state)
    const [removeComments, setRemoveComments] = React.useState(true);
    const [displayComments, setDisplayComments] = React.useState(false);
    const [minHeight, setMinHeight] = React.useState(0)
    const [commentInput, setCommentInput] = React.useState('')

    React.useEffect(()=>{
        shouldDisplayAnnouncement()
    }, [announcement])

    React.useEffect(()=>{
        if (document.getElementById('Comments') !== null) {
            document.getElementById('Comments').scrollTop = document.getElementById('Comments').scrollHeight
        }   
        if (comments.show) {
            setRemoveComments(false)
            setTimeout(()=>{
                setDisplayComments(true)
                setMinHeight(60)    
            }, 100)
        } else {
            setMinHeight(0)
            setDisplayComments(false)
            setTimeout(()=>{
                setRemoveComments(true)
            }, 300)

        }
    }, [comments])

    const handleChange = (e) => {
        setCommentInput(e.target.value);
    }

    const handleOnKeyPressDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleSubmit = () => {
        //Command to submit to firebase goes here
        setCommentInput('')
        firebase.submit({ userInput: commentInput, dateInput: new Date().getTime() })
    }

    const hideAnnouncement = () => {
        localStorage.setItem('l_av', announcement.id)
        setShowAnnouncement(false)
    }

    const convertDate = (date) => {
        return moment(date).format('MM/DD/YYYY h:mm a')
    }

    const shouldDisplayAnnouncement = () => {
        if (announcement.id !== null 
            && announcement.text !== ""
            && localStorage.getItem('l_av') != announcement.id) {
            setShowAnnouncement(true)
        } else {
            setShowAnnouncement(false)
        }
    }


    return (
        <div id="Comments" className="Comments rack__drawer" style={{display: 'block', minHeight: minHeight + '%', paddingBottom: '0.5rem', bottom: 0}}>
            <div className="comment-header">
                <div style={{display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', fontSize: '1.5rem', padding: '0 8px 2px'}}>
                    <div style={{margin: '0 auto'}}>
                        Alerts
                    </div>
                    <div>
                        <Close style={{fontSize: '1.8rem'}} onClick={()=>dispatch(Actions.openComments(false))} />
                    </div>
                </div>
                <div style={{borderTop: '1px solid #CCC', padding: '8px 12px 2px'}}>
                    <div style={{display: 'flex', width: 'calc(100%)'}}>
                        <div style={{flex: '1 1 auto'}}>
                            <TextField 
                                value={commentInput} 
                                onChange={handleChange} 
                                style={{width: '100%'}} 
                                placeholder={placeholder}
                                onKeyPress={handleOnKeyPressDown}
                            />
                        </div>
                        <div style={{
                            display: "flex",
                            maxHeight: "2em",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                            marginLeft: 8,
                            cursor: 'pointer'
                        }}
                        onClick={handleSubmit}
                        >
                            <SendOutlined />
                        </div>
                    </div>
                </div>
            </div>
            <div id="comment-box" className="comment-box">
                {
                   Object.keys(comments.dataset).map(key => 
                        <div id={key} className="comment-message" key={key}>
                            {
                                comments.dataset[key].dateInput &&
                                convertDate(comments.dataset[key].dateInput) + ': '
                            }
                            {
                                comments.dataset[key].userInput
                            }
                        </div>
                    )
                }
                {
                    showAnnouncement &&
                    <div style={{display: 'flex', justifyContent: 'space-between', color: "#2ebbb3"}}>
                        <div><span>Announcement <img src={Logo} width={19} /> </span> : {announcement.text}</div>
                        <div><Close style={{color: "#FFF"}} onClick={hideAnnouncement} /></div>
                    </div>
                }
            </div>
        </div>
    )

}