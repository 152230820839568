import {combineReducers} from 'redux';
import home from './home';
import announcement from './announcement';
import comments from './comments';
import drawers from './drawers';
import firebase from './firebase';
import emergencyInfo from './emergency-info';
import menu from './site/menu';
import location from './location';
import maps from './maps';
import map_services from './maps/services';
import media from './media';
import jobs from './jobs';
import news from './news';
import profiles from './profiles';
import services from './services';
import station_maps from './maps/stations';
import Alerts from './site/alerts';
import Resources from './resources';
import stations from './maps/stations';

const createReducer = (asyncReducers) =>
    combineReducers({
        alerts: Alerts,
        announcement: announcement,
        comments: comments,
        drawers: drawers,
        emergencyInfo: emergencyInfo,
        firebase: firebase,
        home: home,
        location: location,
        jobs: jobs,
        maps: maps,
        map_services: map_services,
        media: media,
        menu: menu,
        news: news,
        resources: Resources,
        services: services,
        stations: station_maps,
        profiles: profiles,
      ...asyncReducers
    });

export default createReducer;
