
import React from 'react';
import { Link } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { Divider, Select, MenuItem, Input, InputLabel, makeStyles, Button } from '@material-ui/core';
import Locations from '../../../utils/data/locations.json';
import { getCountryName, getStateName } from '../../../utils/names';
import history from '../../../history';
import * as Actions from '../../../store/actions';

const useStyles = makeStyles((theme) => ({
    selectDiv: {
        width: '100%',
        marginBottom: '3rem'
    },
    select: {
      width: '100%',
    },
    button: {
        backgroundColor: "#000"
    },
    inputRoot: {
        '&::after': {
            borderBottomColor: '#2ebbb3',
        },
    }
}));


const FacilitySelectDrawer = (props) => {

    const {maps} = useSelector(state => state)
    const [country, setCountry] = React.useState(null)
    const [state, setState] = React.useState(null)
    const [facility, setFacility] = React.useState(null)
    const mapFrom = process.env.NODE_ENV === 'development' ? Locations : maps

    const classes = useStyles()

    const dispatch = useDispatch()

    const handleSelect = (type) => (e) => {
        switch (type) {
            case 'country':
                setCountry(e.target.value)
                setState(null)
                setFacility(null)
                break;
            case 'state':
                setState(e.target.value)
                setFacility(null)
                break;
            case 'facility':
                setFacility(e.target.value)
                break;
            default:
                break;
        }
    }

    const handleSubmit = () => {
        localStorage.setItem('is_returning', true)
        localStorage.setItem('l_m', JSON.stringify({country: country, state: state, map: facility, map_type: 'facility'}))
        dispatch(Actions.setUserCountryRegion(country))
        dispatch(Actions.setUserStateRegion(state))
        dispatch(Actions.setLocation(facility))
        dispatch(Actions.setMapType('facility'))
        history.push('/')
        props.close()
    }

    return (
        <div style={{backgroundColor: '#FFF', position: 'absolute', bottom: 0, width: '100%', height: props.open ? '100%' : 0, zIndex: '999'}}>
            <div style={{display: 'flex', flexDirection: 'column', color: "#000"}}>
                <div style={{display: 'flex', justifyContent: 'space-between', padding: 12, fontSize: 20, fontWeight: 'bold', borderBottom: '1px solid #AAA'}}>
                    <div>
                        Choose Facility
                    </div>
                    <div onClick={props.close}>
                        <CloseIcon />
                    </div>
                    
                </div>
                <div style={{padding: 16}}>
                    <div className={classes.selectDiv}>
                    <InputLabel id="country-select-label">Select Country</InputLabel>
                    <Select
                    labelId="country-select-label"
                    id="country-select"
                    value={country}
                    onChange={handleSelect('country')}
                    style={{width: '100%'}}
                    input={<Input classes={{
                        root: classes.inputRoot,
                      }} /> }
                    > 
                        {Object.keys(mapFrom).map(ct => 
                            <MenuItem value={ct}>{getCountryName(ct)}</MenuItem>
                        )}
                    </Select>
                    </div>
                    {
                        country !== null &&
                        <div className={classes.selectDiv}>
                        <InputLabel id="state-select-label">Select Region</InputLabel>
                        <Select
                        labelId="state-select-label"
                        id="state-select"
                        value={state}
                        onChange={handleSelect('state')}
                        style={{width: '100%'}}
                        input={<Input classes={{
                            root: classes.inputRoot,
                          }} /> }
                        >
                            {Object.keys(mapFrom[country]).map(st => 
                                <MenuItem value={st}>{getStateName(country, st)}</MenuItem>
                            )}
                        </Select>
                        </div>
                    }
                    {
                        state !== null &&
                        <div className={classes.selectDiv}>
                        <InputLabel id="facility-select-label">Select Facility</InputLabel>
                        <Select
                        labelId="facility-select-label"
                        id="facility-select"
                        value={facility}
                        onChange={handleSelect('facility')}
                        style={{width: '100%'}}
                        input={<Input classes={{
                            root: classes.inputRoot,
                          }} /> }
                        >
                            {Object.keys(mapFrom[country][state]).map(id => 
                                <MenuItem value={id}>{id.split('_').join(' ')}</MenuItem>
                            )}
                        </Select>
                        </div>
                    }
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                        <Button 
                        variant="contained" 
                        color="primary"
                        disabled={facility === null}
                        onClick={handleSubmit}
                        className={classes.button}
                        >
                        Go</Button>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default FacilitySelectDrawer;