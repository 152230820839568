import * as Actions from '../../actions';

const initialState = {
    show: false
};

const Menu = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.OPEN_MENU:
        {
            return {
                ...state,
                show: action.payload
            }
        }
        default:
        {
            return state;
        }
    }
};

export default Menu;