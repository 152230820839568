import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as Actions from '../../store/actions';

export class Profile {
    constructor(profile, options={}) {
        this.profile = profile || {}
        this.id = profile ? profile.id : undefined
        this.dispatch = options.dispatch || useDispatch()
        this.store = options.state || useSelector(state => state)
    }

    init() {
        if (this.store.profiles[this.id]) {
            this.profile = this.store.profiles[this.id]
        }
    }

    exists() {
        return this.store.profiles[this.id] && this.store.profiles[this.id] !== null
    }

    getDescription() {
        return this.profile.description
    }

    getImages() {
        return this.profile.images
    }

    getLogo() {
        return this.profile.logo
    }

    getName() {
        return this.profile.name
    }

    hasLogo() {
        return this.profile.logo && this.profile.logo !== "null"
    }

    hasImages() {
        return this.profile.images && this.profile.images.length > 0
    }

    loaded() {
        return this.store.profiles[this.id] !== undefined
    }

}