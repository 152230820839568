
import React from 'react';

const Contact = (props) => {
    

    return (
        <div className="page">
            <div id="contact" className="page-content">
                For questions or inquiry, send emails to:
                <br/><br/>
                <a href="mailto:rackdevops@gmail.com">rackdevops@gmail.com</a>
            </div>
        </div>
    )
}

export default Contact;