import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as Actions from '../../store/actions';
import { Map } from '../../utils/classes/map';
import { SpeedDial } from '../../components/fragments';
import { Build, Directions, Info, LocalShipping, LocationCity, Edit } from '@material-ui/icons';
import { getFilterDescription } from './description';
import { Redirect } from 'react-router';
import FilterIcon from './components/filtericon';

const infoWindow = (id, locations, map) => {
  var infowindow = new window.google.maps.InfoWindow();

  var marker, i;

  for (i = 0; i < locations.length; i++) {

    marker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(locations[i].center[0],locations[i].center[1], locations[i].position),
      map: map
    });

    window.google.maps.event.addListener(marker, 'click', (function(marker, i) {
      return function() {
        let content = `
          <h1 id='firstHeading' class='firstHeading'>${locations[i].heading}</h1>
          ${locations[i].description.map(txt => `<p><b>${txt}</b></p>`).join('')}
        `
        if (locations[i].evac_map && locations[i].evac_map !== null) {
          content += `<b><span class='emergency-info' data-map=${id} data-marker_index=${i}> Emergency Info : </span></b>`
        }
        infowindow.setContent(content);
        infowindow.open(map, marker);
      }
    })(marker, i));

    // Create the initial InfoWindow.
    var infoWindow = new window.google.maps.InfoWindow(
      { position: new window.google.maps.LatLng(34.059090, -117.368086) }
    );
    infoWindow.close(map);

    // Configure the click listener.
    map.addListener('click', function(mapsMouseEvent) {
      // Close the current InfoWindow.
      infoWindow.close();

      // Create a new InfoWindow.
      infoWindow = new window.google.maps.InfoWindow({position: mapsMouseEvent.latLng});
      infoWindow.setContent(mapsMouseEvent.latLng.toString());
      infoWindow.close(map);////opens and close lanlng window.. as of now it set closed
    });

  }
}


const Home = (props) => {
  const {location, maps, map_ext} = useSelector(state => state) 
  const [filterAlertOpen, setFilerAlertOpen] = React.useState(false);
  const [filter, setFilter] = React.useState('facilities');
  const [dialOpen, setDialOpen] = React.useState(false);
  const dispatch = useDispatch()

  const MAP = new Map({country: location.country, state: location.state, id: location.map, city: location.city, mapType: location.map_type})
  MAP.init()

  const filterAlertClose = () => setFilerAlertOpen(false)

  const handleDialItemClick = (filter) => (e) => {
    if (MAP.getMapType() === 'station') {
      if (filter === 'draw') {
        dispatch(Actions.setStationDrawer({show: true, map: MAP, type: 'draw'}))
      }

      if (filter === 'view') {
        dispatch(Actions.setStationDrawer({show: true, map: MAP, type: 'image'}))
      }
    } else {
      setFilter(filter)
    }

    setDialOpen(false)
  }

  React.useEffect(()=>{

    if (document.getElementById('map')) {
      const zoom = MAP.getZoom(filter)
      const center = MAP.getCenter(filter)

      const map = new window.google.maps.Map(document.getElementById('map'), {
          ...MAP.getMap(),
          zoom: zoom,
          center: new window.google.maps.LatLng(center[0], center[1]),
          mapTypeId: window.google.maps.MapTypeId[MAP.getMapTypeId()]
      })
  
      infoWindow(MAP.getId(), MAP.getMarkers(filter), map)
    }

    if (MAP.getMapType() === 'station') {
      if (MAP.hasStationRoutes()) {
        handleDialItemClick('view')()
      }
    }

        
  }, [])

  React.useEffect(()=>{
    if (document.getElementById('map')) {
      const zoom = MAP.getZoom(filter)
      const center = MAP.getCenter(filter)
  
      const map = new window.google.maps.Map(document.getElementById('map'), {
           ...MAP.getMap(),
          zoom: zoom,
          center: new window.google.maps.LatLng(center[0], center[1]),
          mapTypeId: window.google.maps.MapTypeId[MAP.getMapTypeId()],
      })
  
      infoWindow(MAP.getId(), MAP.getMarkers(filter), map)
    }

  }, [filter])

  const items = {
    'facility': [
      {icon: <LocationCity />, onClick: handleDialItemClick('facilities')},
      {icon: <Build />, onClick: handleDialItemClick('repair_shops')},
      {icon: <LocalShipping />, onClick: handleDialItemClick('truck_stops')}
    ],
    'station': [
      {icon: <Edit />, onClick: handleDialItemClick('draw')},
      {icon: <Directions />, onClick: handleDialItemClick('view')},
    ]
  }
      
 

  if (localStorage.getItem('is_returning') !== 'true') {
    return <Redirect to={'/select/map'} />
  }


  return (
    <div className="page">
      {
        <div className="map-filter">
          <SpeedDial
            type={MAP.getMapType()}
            icon={<FilterIcon mapType={MAP.getMapType()} filter={filter} />}
            open={dialOpen}
            handleOpen={()=>setDialOpen(!dialOpen)}
            items={items[MAP.getMapType()]} />
        </div>
      }
      {
        <button className="map-filter-description"
          onClick={() => dispatch(
            Actions.openDescriptionAlert(
              true,
              getFilterDescription(
                MAP.getMapType(),
                filter
              ))
          )}
          >
        <Info style={{color: 'rgb(86, 86, 86)'}} />
      </button>
    }
    <div id="map" className="map">
    </div>
  </div>
  )

}

export default Home;