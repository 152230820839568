import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import { Close, Settings, Menu } from '@material-ui/icons'
import * as Actions from '../../../../store/actions';
import ToolsMenu from './components/tools_menu';

const strokeColors = {
  default: '#45B39D',
  caution: '#F1C40F',
  danger: '#C0392B'
}

function initMap(data, el) {
  const map = new window.google.maps.Map(el, {
    zoom: data.zoom,
    center: new window.google.maps.LatLng(data.center[0], data.center[1]),
    mapTypeId: 'satellite',
    labels: data.labels,
    mapTypeId: window.google.maps.MapTypeId[data.mapTypeId],
    disableDefaultUI: true,
  });
}

const StationEditDrawer = (props) => {
  const dispatch = useDispatch()
  const { drawers } = useSelector(state => state)
  const [display, setDisplay] = React.useState(false)
  let station = drawers.station
  let MAP = station.map
  const canvasRef = React.useRef(null)
  const contextRef = React.useRef(null)

  React.useEffect(() => {

    const canvas = canvasRef.current
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    const context = canvas.getContext('2d')
    context.strokeStyle = strokeColors['default']
    context.lineWidth = 5
    contextRef.current = context

  }, [])

    React.useEffect(() => {
        if (!station.show && display) {
          setDisplay(false);
        } else if (station.show && station.type === 'draw') {
            setDisplay(station.show)
            contextRef.current.clearRect(0, 0, window.innerWidth, window.innerHeight)
            contextRef.current.strokeStyle = strokeColors['default']
        }
    }, [station])
    
    React.useEffect(() => {

      if (MAP !== null) {
        initMap(MAP.map, document.getElementById('stat_map'))
        document.body.style.position = "fixed"
        document.body.style.width = "100%"
      } else {
        document.body.removeAttribute('style')
      }
        
    }, [display])
  
    const findXY = (event) => {
      let x = 0, y = 0;
      
      if (window.innerWidth > 680) {
          
          if (!event || event.buttons !== 1) {
            return;
          }

          x = event.clientX;
          y = event.clientY

        } else {
          

          if (!event || !event.touches || event.touches.length === 0) {
            return;
          }

          x = event.touches[0].clientX
          y = event.touches[0].clientY

        }
      
        return {x, y}
    }
    
    const startDraw = (event) => {
      
      const pos = findXY(event)
  
      if (!pos) {
        return
      }
      
      const {x, y} = pos

      contextRef.current.beginPath()
      contextRef.current.moveTo(x, y - 40)
    }

    const draw = (event) => {
        
      const pos = findXY(event)
      
        if (!pos) {
          return
        }
        
        const {x, y} = pos

        contextRef.current.lineTo(x, y - 40)
        contextRef.current.stroke()

    }


    const handleOnClose = (e) => {
      dispatch(Actions.setStationDrawer({ show: false, map: null, type: null }))
    }

    return (
      <div className="Drawer" style={{
        display: 'block',
        minHeight: display ? '100%' : 0,
        transform: display ? 'translateY(3px)' : 'translateY(102px)',
        zIndex: 999
      }}>
        {
          display &&
          <>
            <div className="drawer-header">
              <div className="drawer-close" style={{ color: '#fff' }} onClick={handleOnClose}><Close /></div>
              <div style={{display: "flex"}}>
                <div>
                  {
                    display && 
                    <ToolsMenu
                      context={contextRef.current}
                      address={MAP !== null ? MAP.map.address : null}
                    />
                  }
                </div>
              </div>
            </div>
          </>
        }
        <div id="station_can_div" style={{ height: '100%', position: 'relative' }}
        >
          <div id="stat_map" className="map" style={{maxHeight: `100%`}}>
          </div>
          <div id="canvas_container" style={{ height: '100%', position: 'absolute', width: '100%', top: 0, left: 0 }}>
            <div id="canvas_container_relative" style={{position: 'relative', height: 'inherit'}}>
            <canvas
              id="station_canvas"
              style={{ position: "fixed", background: 'transparent'}}
              ref={canvasRef}
              onMouseDown={startDraw}
              // onMouseUp={endDraw}
              onMouseMove={draw}
              onTouchStart={startDraw}
              onTouchMove={draw}
            ></canvas>
            </div>
          </div>
        </div>
        </div>
    )

}

export default StationEditDrawer