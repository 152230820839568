import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import { Close, Edit, NavigateBefore, NavigateNext } from '@material-ui/icons'
import Comment from './tools/comment';
import * as Actions from '../../../../store/actions';

const StationImageDrawer = (props) => {
    const [display, setDisplay] = React.useState(false)
    const { drawers } = useSelector(state => state)
    const dispatch = useDispatch();
    const [imageIndex, setImageIndex] = React.useState(0)
    let {station} = drawers
    const close = () => setDisplay(false)
    const MAP = station.map

    React.useEffect(() => {
        if (!station.show && display) {
            setDisplay(false)
        } else if (station.show && station.type === 'image') {
            setDisplay(true)
        }
    }, [station])

    const handleNavigation = (dir) => (e) => {
        if (dir === 'prev') {
            if (imageIndex == 0) {
                return;
            } 
            setImageIndex(imageIndex - 1)
        } else if (dir === 'next') {
            if (imageIndex === MAP.map.images.length - 1) {
                setImageIndex(0)
                return;
            }
            setImageIndex(imageIndex + 1)
        }
    }

    const NavigateToEdit = () => {
        dispatch(Actions.setStationDrawer({show: true, map: MAP, type: 'draw'}))
    }


    return (
        <div className="Drawer" style={{
            display: 'block',
            minHeight: display ? '100%' : 0,
            transform: display ? 'translateY(3px)' : 'translateY(102px)',
            background: "#000"
        }}>
            {
                display &&
                <>
                <div className="drawer-header">
                        <div className="drawer-close" style={{ color: '#fff' }} onClick={close}><Close /></div>
                <div>
                    <div onClick={() => { }} style={{ marginRight: 16, color: '#FFF' }}>
                        <Edit style={{marginRight: 12}} onClick={NavigateToEdit} />
                        <Comment MAP={MAP} imageIndex={imageIndex} />   
                    </div>
                </div>
                </div>
                </>
            }
            <div style={{
                color: "#FFF",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: "calc(100% - 96px)",
                textAlign: 'center',
                position: 'relative'
            }}>
                {
                    !MAP || MAP === null || !MAP.map.images || MAP.map.images.length === 0  ?
                        <h2 style={{ paddingLeft: 16, paddingRight: 16 }}>We currently have no routes for this station.</h2>
                        :
                        <React.Fragment>
                            <img
                                src={MAP.map.images[imageIndex].image}
                                style={{maxHeight: 'calc(100% - 1px)', maxWidth: '100%'}}
                            />
                            {
                                MAP.map.images.length > 1 &&
                                <>
                                <div style={{position: 'absolute', top: '50%', left: 0}}>
                                    <div onClick={handleNavigation('prev')}>
                                        <NavigateBefore style={{ fontSize: '2rem' }} />
                                    </div>
                                </div>
                                <div style={{position: 'absolute', top: '50%', right: 0}}>
                                    <div onClick={handleNavigation('next')}>
                                        <NavigateNext style={{ fontSize: '2rem' }} />
                                    </div>
                                </div>
                                </>      
                             }
                        </React.Fragment>
                }
   
            </div>
        </div>
    )

}

export default StationImageDrawer