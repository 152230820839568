import * as Actions from '../../actions';

const initialState = {
    test: null
};

const Home = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.TEST:
        {
            return {
                ...state,
                test: action.payload
            }
        }
        default:
        {
            return state;
        }
    }
};

export default Home;