
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as Actions from '../store/actions';
import Locations from '../utils/data/locations.json';
import { Divider, FormControl, MenuItem, NativeSelect } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import history from '../history.js';
import { ArrowDropDown } from '@material-ui/icons';
import { getCountryName, getStateName } from '../utils/names';

const useStyles = makeStyles((theme) => ({
    selectEmpty: {
        padding: 0,
        margin: 0,
        color: "#FFF",
        width: '100%',
        fontSize: 18
    },
    icon: {
        fill: '#FFF'
    }
  }));


const Search = (props) => {
    const {location, maps} = useSelector(state => state)
    const [currCountry, setCurrCountry] = React.useState(location.country)
    const [currState, setCurrState] = React.useState(location.state)
    const mapFrom = process.env.NODE_ENV === 'development' ? Locations : maps
    const classes = useStyles()

    React.useEffect(()=>{
        if (!mapFrom[currCountry][currState]) {
            setCurrState('CA')
        }
    }, [])

    const dispatch = useDispatch()

    const setLocation = (country, state, id) => (e) => {
        if (id === location.map) {
            return;
        }
        dispatch(Actions.setUserCountryRegion(country))
        dispatch(Actions.setUserStateRegion(state))
        dispatch(Actions.setUserCityRegion(null))
        dispatch(Actions.setLocation(id))
        dispatch(Actions.setMapType('facility'))

        localStorage.setItem('l_m', JSON.stringify({country: country, state: state, city: null, map: id, map_type: 'facility'}))

        history.push('/')
    }

    const openStateFacilities = (state) => {
        setCurrState(state === currState ? null : state)
    }
    
    return (
        <div className="page">
            <div id="search" className="page-content" style={{padding: 0, paddingTop: 12}}>
                <div style={{padding: 8}}>
                <NativeSelect
                    disableUnderline
                    value={currCountry}
                    onChange={(e)=>{setCurrCountry(e.target.value)}}
                    name="age"
                    inputProps={{ 'aria-label': 'age' }}
                    classes={{
                        icon: classes.icon
                    }}
                    className={classes.selectEmpty}
                    >
                    {
                        Object.keys(mapFrom).sort().map(country =>
                            <option value={country}>{getCountryName(country)}</option>
                        )
                    }
                </NativeSelect>
                </div>
                <Divider />
                <ul className="list-default">
                {
                    Object.keys(mapFrom[currCountry]).sort().map(state =>
                        <> 
                        <li style={{padding: 8, cursor: 'pointer', backgroundColor: state === currState ? '#222' : undefined, fontWeight: state === currState ? 'bold' : undefined}} onClick={()=>openStateFacilities(state)}>{getStateName(currCountry, state)}</li>
                        <Divider />
                        {
                            state === currState &&
                            Object.keys(mapFrom[currCountry][currState]).map(id => 
                                <>
                                    <li style={{padding: 8, cursor: 'pointer', fontWeight: id === location.map ? 'bold': undefined}} onClick={setLocation(currCountry, state, id)}>{id.split('_').join(' ')}</li>    
                                    <Divider />
                                </>
                            )
                        }
                        </>
                    )
                }
                </ul>
            </div>
        </div>
    )
}

export default Search;