import * as Actions from '../../actions';

const initialState = {
    items: [
        {title: 'test', link: 'https://www.google.com'}
    ]
};

const News = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.ADD_NEWS:
        {
            return {
                items: action.payload
            }
        }
        default:
        {
            return state;
        }
    }
};

export default News;