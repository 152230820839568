import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Locations from '../data/locations.json';
import Stations from '../data/stations.json';
import * as Actions from '../../store/actions';

export class Map {
    constructor(map, options={}) {
        this.map = map || {}
        this.state = map ? map.state : undefined
        this.country = map ? map.country : undefined
        this.city = map ? map.city : undefined
        this.mapType = map ? map.mapType : undefined
        this.mapFrom = null;
        this.id = map ? map.id : undefined
        this.dispatch = options.dispatch || useDispatch()
        this.store = options.state || useSelector(state => state)
    }

    init() {
        this.setMapFrom();

        const useDefaultMap = () => {
            this.map = this.mapFrom["US"]["CA"]["Kinder_Morgan_Colton_Terminal"]
            localStorage.setItem('l_m', JSON.stringify({country: "US", state: "CA", map: "Kinder_Morgan_Colton_Terminal", map_type: "facility"}))
            this.dispatch(Actions.setLocation('Kinder_Morgan_Colton_Terminal'))
        }

        const useStationMap = () => {
            this.map = this.mapFrom[this.country][this.state][this.city][this.id]
        }

        const useFacilityMap = () => {
            this.map = this.mapFrom[this.country][this.state][this.id]
        }

        switch (process.env.NODE_ENV) {
            // case 'development':
            //     if (this.mapFrom[this.country][this.state] && this.mapFrom[this.country][this.state][this.id]) {
            //         this.map = Locations[this.country][this.state][this.id]
            //     } else {
            //         localStorage.setItem('l_m', JSON.stringify({country: "US", state: "CA", map: "Kinder_Morgan_Colton_Terminal"}))
            //         this.map = Locations["US"]["CA"]["Kinder_Morgan_Colton_Terminal"]
            //         this.dispatch(Actions.setLocation('Kinder_Morgan_Colton_Terminal'))
            //     }
            //     break;
            case 'test':
                this.map = this.mapFrom["US"]["CA"]["Kinder_Morgan_Colton_Terminal"]
                break;
            default:
                if (this.mapType === 'station') {
                    if (!this.city || this.city === null) {
                        useDefaultMap()
                        return;
                    }

                    if (!this.mapFrom[this.country][this.state][this.city][this.id]) {
                        useDefaultMap()
                        return;
                    }

                    useStationMap()
                    return;
                }

                if (this.mapType === 'facility') {
                    if (!this.mapFrom[this.country][this.state][this.id]) {
                        useDefaultMap()
                        return;
                    }

                    useFacilityMap()
                    return;
                }

                useDefaultMap()
                // if (this.mapFrom[this.country][this.state] && this.mapFrom[this.country][this.state][this.id]) {
                //     if (this.city && this.city !== null) {
                //         this.map = this.mapFrom[this.country][this.state][this.city][this.id]
                //     } else {
                //        this.map = this.mapFrom[this.country][this.state][this.id]
                //     }
                // } else {
                //     this.map = this.mapFrom["US"]["CA"]["Kinder_Morgan_Colton_Terminal"]
                //     localStorage.setItem('l_m', JSON.stringify({country: "US", state: "CA", map: "Kinder_Morgan_Colton_Terminal", map_type: "facility"}))
                //     this.dispatch(Actions.setLocation('Kinder_Morgan_Colton_Terminal'))
                // }
                break;
        }
    }

    getCenter() {
        return this.map.center
    }

    getId() {
        return this.id
    }

    getMap() {
        return this.map
    }

    getMapTypeId() {
        return this.map.mapTypeId
    }

    getMapType() {
        return this.mapType
    }

    getMarkers(filter) {
        if (!filter || filter === 'facilities') {
            return this.map.markers
        } else {
           return this.store.map_services[filter].markers
        }
    }

    getZoom(filter) {
        if (!filter || filter === 'facilities') {
            return this.map.zoom
        } else {
            return this.store.map_services[filter].zoom
        }
    }

    hasExt() {
        return this.store.map_ext[this.id] !== undefined && this.store.map_ext[this.id] !== null
    }

    hasStationRoutes() {
        if (!this.getMapType() || !this.map.images || this.map.images.length === 0) {
            return false
        }
        
        return true
    }

    setMapFrom() {
        switch (process.env.NODE_ENV) {
            case 'development':
                this.mapFrom = Locations;
                if (this.mapType === 'station') {
                    this.mapFrom = Stations
                }
                break;
            case 'test':
                this.map = Locations["US"]["CA"]["Kinder_Morgan_Colton_Terminal"]
                if (this.mapType === 'station') {
                    this.map = Stations["US"]["CA"]["Tesoro_East_Hynes_Terminal"]
                }
                break;
            default:
                this.mapFrom = this.store.maps
                if (this.mapType === 'station') {
                    this.mapFrom = this.store.stations
                }
                break;
        }

    }

}