import {combineReducers} from 'redux';
import mechanics from './mechanics';
import rentals from './rentals';
import tires from './tires';

export default combineReducers({
    mechanics: mechanics,
    rentals: rentals,
    tires: tires,
});
