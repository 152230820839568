import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import Home from '../pages/home';
import About from '../pages/about';
import Contact from '../pages/contact';
import Resources from '../pages/resources';
import Media from '../pages/media';
import Services from '../pages/services';
import Search from '../pages/search';
import Jobs from '../pages/jobs';
import News from '../pages/news';
import Profile from '../pages/profile';
import Requests from '../pages/requests';
import Service from '../pages/service';
import SelectMap from '../pages/selectMap';
import Stations from '../pages/stations';

const PropRoute = (props) => {

    const {component, exact, path, withKey} = props

    return (
        <Route path={path} exact={exact} component={
            (props) => 
              <React.Fragment key={withKey ? new Date().getTime() : undefined}>
                {React.cloneElement(component, {...props})}
              </React.Fragment>
        } />
    )
}

const ComingSoon = (props) => {

    return (
        <div className="page">
            <div style={{padding: 16}}>
                <h1 style={{color: "#FFF"}}>Coming Soon...</h1>
            </div>
        </div>
    )

}

const Routes = ({...props}) => {

    return (
          <React.Fragment>   
            <Switch>
                <Route exact path="/about">
                    <About />
                </Route>
                <Route exact path="/contact">
                    <Contact />
                </Route>
                <Route exact path="/resources">
                    <Resources />
                </Route>
                <Route exact path="/rentals">
                    <ComingSoon />

                    {/* <Service type="rentals" /> */}
                </Route>
                <Route exact path="/tires">
                    <ComingSoon />

                    {/* <Service type="tires" /> */}
                </Route>
                <Route exact path="/search">
                    <Search />
                </Route>
                <Route exact path="/services">
                    <Services />
                </Route>
                {/* <Route path="/news">
                    <News />
                </Route> */}
                <Route exact path="/mechanics">
                    <ComingSoon />
                    {/* <Service type="mechanics" /> */}
                </Route>
                <PropRoute path="/jobs/:country/:state/:id" component={<Jobs />} withKey={true} />
                <Route path="/jobs">
                    <Jobs />
                </Route>
                <PropRoute path="/media/:id" component={<Media />} withKey={true} />
                <Route exact path="/media">
                    <Media />
                </Route>
                <PropRoute path="/requests/:type" component={<Requests />} />
                <Route exact path="/stations">
                    <Stations />
                </Route>
                <Route exact path="/select/map">
                    <SelectMap />
                </Route>
                <PropRoute path="/:id" component={<Profile />} withKey={true} />
                <Route exact path="/">
                    <Home />
                </Route>
            </Switch>    
          </React.Fragment>
    );
};

export default Routes;
