import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from '@material-ui/core';

const CityItemsList = (props) => {
    const { location } = useSelector(state => state)
    const items = Object.keys(props.cities[props.currCity])

    if (items.length === 0) {
        return <></>
    }

    return (
        <>
            {
                items.map(id => 
                    <>
                        <li
                            style={{
                                padding: '8px 8px 8px 32px',
                                cursor: 'pointer',
                                fontWeight: id === location.map ? 'bold' : undefined
                            }}
                            onClick={() => props.setLocation(id)}
                        >
                            {id.split('_').join(' ')}
                        </li>    
                        <Divider />
                    </>    
                )
            }
        </>
    )
}

export default CityItemsList