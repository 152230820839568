import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import GoogleMapsApi from '../../lib/googlemaps';
import {google_maps_api_key} from '../../keys.json';
import Locations from '../../utils/data/locations.json';
import * as Actions from '../../store/actions';
import { EvacuationDrawer } from '../fragments/drawers/evacuation.drawer';
import StationEditDrawer from '../fragments/drawers/stationedit_drawer';
import StationImageDrawer from '../fragments/drawers/stationimage_drawer';

export default function GoogleMaps (props) {
    const {location} = useSelector(state => state)
    const dispatch = useDispatch()
    const [isMapsLoaded, setIsMapsLoaded] = React.useState(false)

    React.useEffect(()=>{
        const gmapApi = new GoogleMapsApi(google_maps_api_key)
        gmapApi.load().then(() => {
            setIsMapsLoaded(true)
        })

        window.addEventListener('click', (e)=>{
            if (e.target.classList.contains("emergency-info")) {
                let map = Locations[location.country][location.state][e.target.dataset.map]
                if (map) {
                    dispatch(Actions.addEvacuation({
                        show:true,
                        info: map.markers[e.target.dataset.marker_index].evac_map
                    }))
                }
            }
        })

    }, [])

    if (!isMapsLoaded) {
        return <></>
    }

    return (
        <React.Fragment>
            {props.children}
            <EvacuationDrawer />
            <StationEditDrawer />
            <StationImageDrawer />
        </React.Fragment>
    )
}