import * as Actions from '../../actions';

const initialState = {
};

const Profiles = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.ADD_PROFILE:
        {
            return {
                ...action.payload
            }
        }
        default:
        {
            return state;
        }
    }
};

export default Profiles;