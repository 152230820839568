import * as Actions from '../../actions';

const initialState = {
    country: "US",
    state: "CA",
    city: null,
    map: 'Kinder_Morgan_Colton_Terminal',
    map_type: 'facility'
};

const Location = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.SET_LOCATION:
        {
            return {
                ...state,
                map: action.payload
            }
        }
        case Actions.SET_STATE:
        {
            return {
                ...state,
                state: action.payload
            }
        }
        case Actions.SET_COUNTRY:
        {
            return {
                ...state,
                country: action.payload
            }
        }
        case Actions.SET_CITY:
        {
            return {
                ...state,
                city: action.payload
            }
        }
        case Actions.SET_MAP_TYPE:
        {
            return {
                ...state,
                map_type: action.payload
            }
        }
        default:
        {
            return state;
        }
    }
};

export default Location;