import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import { Close } from '@material-ui/icons'
import * as Actions from '../../../store/actions';

function calculateAndDisplayRoute(directionsService, directionsRenderer, origin, destination, type) {
    directionsService.route(
      {
            origin: origin,
            destination: destination,
            // Note that Javascript allows us to access the constant
            // using square brackets and a string value as its
            // "property."
      
            travelMode: window.google.maps.TravelMode[type]
      },
      (response, status) => {
        if (status == "OK") {
          directionsRenderer.setDirections(response);
        } else {
          window.alert("Directions request failed due to " + status );
        }
      }
    );
 }

function initMap(data, el) {
  console.log(data)
    const map = new window.google.maps.Map(el, {
        zoom: data.zoom,
        center: data.center,
        mapTypeId: 'satellite',
        labels: data.labels,
        mapTypeId: window.google.maps.MapTypeId[data.mapTypeId],
      });

      if (data.useDirectionsRenderer) {
        const directionsService = new window.google.maps.DirectionsService();  
        const directionsRenderer = new window.google.maps.DirectionsRenderer({
            map: map,
            preserveViewport: true  //Added to preserve viewport
        });
        calculateAndDisplayRoute(directionsService, directionsRenderer, data.origin, data.destination, data.type);
      } 
      else 
      {
        const flightPath = new window.google.maps.Polyline({
            path: data.flight_plan_coordinates,
            geodesic: true,
            strokeColor: "#1a73e8",
            strokeOpacity: .60,
            strokeWeight: 6
          });
    
          Object.keys(data.markers).map(marker => {
            var marker = new window.google.maps.Marker({
                position: data.markers[marker],
                label: {text: marker, color: "white"},
                map: map,
              });
          })
        
          flightPath.setMap(map);
      }
  
}

export const EvacuationDrawer = (props) => {
    const dispatch = useDispatch()
    const {emergencyInfo} = useSelector(state => state)
    const [displayEvac, setDisplayEvac] = React.useState(false)

    React.useEffect(()=>{
        setTimeout(()=>{
            setDisplayEvac(emergencyInfo.show)
        }, 300)
        if (emergencyInfo.info !== null) {
            initMap(emergencyInfo.info, document.getElementById('evac_map'))
        }
    }, [emergencyInfo])



    const handleOnClose = (e) => {
        dispatch(Actions.addEvacuation({show:false, info: null}))
    }

    return (
      <div className="Drawer" style={{
        display: 'block',
        minHeight: displayEvac ? '100%' : 0,
        transform: displayEvac ? 'translateY(3px)' : 'translateY(102px)',
      }}>
            {
                displayEvac &&
                <>
                <div className="drawer-header">
                <div className="drawer-close" style={{color:'#fff'}} onClick={handleOnClose}><Close /></div>
                <div className="drawer-title" >Emergency Evacuation Muster Point</div>
                </div>
                </>
            }
            <div id="evac_map" className="map" style={{maxHeight: `calc(100% - 86px)`}}>
            </div>
            {
                emergencyInfo.info !== null &&
                <div style={{padding: 12, textAlign: 'center', borderTop: '1px solid #CCC', backgroundColor: '#2b2d2f', color:'#fff'}}>
                    <strong>Mode of Travel: {emergencyInfo.info.type.toLowerCase()}</strong>
                </div>
            }
        </div>
    )

}