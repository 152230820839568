
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Input, makeStyles, TextField } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: "#000"
    },
    div: {
        width: '100%',
        margin: '12px 0',
        '& .MuiInput-underline:after': {
            borderBottomColor: '#2ebbb3',
        }
    },
}))

const request = {
    terminal: {
        placeholder: "Please provide the country, region or state, and the name of the terminal",
        tyText: "We've recieved your request and will inspect the facility. We'll let you know when we've added the facility."
    },
    station: {
        placeholder: "Please provide the country, region or state, address and the name of the station",
        tyText: "We've recieved your request and will inspect the station. We'll let you know when we've added the station."
    }
}

const Requests = (props) => {
    const {firebase} = useSelector(state => state)
    const [sent, isSent] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [text, setText] = React.useState('')
    let requestType = props.match.params.type
    
    const classes = useStyles()

    const handleSubmit = () => {
        firebase.submitFacilityRequest({email, text}, ()=>{isSent(true)})
    }

    return (
        <div className="page" style={{backgroundColor: "#FFF"}}>
            {
                !sent ?
                <div id="requsts-form" className="page-content" style={{color: "#000", padding: 16}}>
                    <p style={{padding: 0, margin: 0}}>To request a {requestType} please fill out the form below</p>
                    <div className={`${classes.div}`}>
                        <TextField name="email" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Please provide your email" style={{width: '100%'}} />
                    </div>
                    <div className={`${classes.div}`} style={{paddingBottom: '2rem'}}>
                        <TextField 
                            name="text"
                            onChange={(e)=>setText(e.target.value)}
                            value={text}
                            multiline 
                            rows={3}
                            rowsMax={4} 
                            placeholder={request[requestType].placeholder} 
                            style={{width: '100%'}} 
                            />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                        <Button className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                    </div>
                </div>
                :
                <div id="requests-thankyou" className="page-content" style={{color: "#000", padding: 16}}>
                    <div style={{textAlign: 'center'}}>
                        <h2>Thank You!</h2>
                        <div>
                            <CheckCircleOutline style={{fontSize: '4.2rem', color: "#2ebbb3"}} />
                        </div>
                        <div>
                            <p>{request[requestType].tyText}</p>
                        </div>
                    </div>
                </div>
            }
            
        </div>
    )
}

export default Requests;