import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Drawer} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import * as Actions from '../../../store/actions';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function MenuDrawer (props) {
    const {menu} = useSelector(state => state)
    const [date, setDate] = React.useState(moment().format('MMMM Do YYYY, h:mm:ss a'))
    const dispatch = useDispatch()
    
    React.useEffect(()=>{
        setInterval(()=>{
            setDate(moment().format('MMMM Do YYYY, h:mm:ss a'))
        }, 1000)

    }, [])


    const close = (e) => {
        dispatch(Actions.openMenu(false))
    }

    const menuItems = [
        { href: '/about', text: 'About' },
        { href: '/resources', text: 'Resources' },
        { href: '/requests/terminal', text: 'Requests (Add a Terminal)' },
        { href: '/requests/station', text: 'Requests (Add a Station)' },
        { href: '/contact', text: 'contact' },
            
    ]
    

    return (
     <Drawer anchor={"right"} open={menu.show} onClose={close}>
         <div style={{minWidth: 281, padding: 12 }}>
             <div onClick={close}>
                <Close style={{color:"#fff"}} />
             </div>
             <div>
                 <div className="list-menu-date">
                     {
                         date
                     }
                 </div>
                 <ul className="list-menu">
                    {
                        menuItems.map(item => 
                            <li>
                                <Link to={item.href} onClick={close}>
                                    {item.text}
                                </Link>
                            </li>
                        )        
                    }
                 </ul>
             </div>
         </div>
      </Drawer>
    )

}