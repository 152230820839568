import React from 'react';
import CommentIcon from '@material-ui/icons/Comment';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';

export default function Comment({ ...props }) {

    const [openAlert, setOpenAlert] = React.useState(false)

    const close = () => setOpenAlert(false)

    const {MAP, imageIndex} = props

    const style = {
        color: "#FFF",
        opacity: 1,
        fontSize: '1.4rem',
        marginTop: 2
    }

    if (!MAP
        || MAP === null
        || !MAP.map.images
        || MAP.map.images.length === 0
        || !MAP.map.images[imageIndex].comment
        || !MAP.map.images[imageIndex].comment === ''
    ) {
        return <CommentIcon style={{...style, opacity: 0.3}} />
    }

    return (
        <>
            <CommentIcon style={style} onClick={()=>setOpenAlert(true)} />
            <Dialog
            open={openAlert}
            onClose={close}
            >
            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
                    {MAP.map.images[imageIndex].comment}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={close} style={{color: 'black'}}>
                OK
                </Button>
            </DialogActions>
            </Dialog>
        </>
    )
}