import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText
} from '@material-ui/core'

export const DescriptionAlert = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {/* <DialogTitle id="alert-dialog-title" style={{color: 'white'}}>{"Welcome"}</DialogTitle> */}
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.close} style={{color: 'black'}}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const WelcomeAlert = (props) => {
    return (
        <Dialog
        open={props.open}
        onClose={props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{color: '#FFF'}}>{"Welcome"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{color: 'black'}}>
            See what's happening at the RACK!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={props.close} style={{color: 'black'}}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
}



