import React from 'react';
import { Divider, FormControl, makeStyles, MenuItem, NativeSelect } from '@material-ui/core';
import { getCountryName, getStateName } from '../../../utils/names';
import { useSelector } from 'react-redux';
import Locations from '../../../utils/data/locations.json';

const useStyles = makeStyles((theme) => ({
    selectDiv: {
        width: '49%',
        '& .MuiInput-underline:after': {
            borderBottomColor: '#2ebbb3',
        }
    },
    selectEmpty: {
        padding: 0,
        margin: 0,
        color: "#FFF",
        width: '100%',
        fontSize: 18
    },
    icon: {
        fill: '#FFF'
    }
}));

export const CountryStateDropdown = (props) => {

    const {country, state} = props
    const {location, maps, firebase, resources} = useSelector(state => state)
    const mapFrom = process.env.NODE_ENV === 'development' ? Locations : maps  
    const classes = useStyles()
    
    return (
        <div style={{display: "flex", justifyContent: "space-between"}}>
        <div className={classes.selectDiv}>
            <NativeSelect 
            name="age"
            inputProps={{ 'aria-label': 'age' }}
            classes={{
                icon: classes.icon
            }}
            className={classes.selectEmpty}
            onChange={props.onChange('country')}
            value={country}
            >
            {
                Object.keys(mapFrom).sort().map(ct =>
                    <option value={ct}>{getCountryName(ct)}</option>
                )
            }
            </NativeSelect>
        </div>
        <div className={classes.selectDiv}>
            <NativeSelect 
            name="age"
            inputProps={{ 'aria-label': 'age' }}
            classes={{
                icon: classes.icon
            }}
            className={classes.selectEmpty}
            onChange={props.onChange('state')}
            value={state}
            >
            <option value={"null"}>Select Region</option>
            {
                Object.keys(mapFrom[country]).sort().map(st =>
                    <option value={st}>{getStateName(country, st)}</option>
                )
            }
            </NativeSelect>
        </div>
        </div>
    )
}