import * as Actions from '../../../actions';

const initialState = {
    items: []
};

const Mechanics = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.ADD_MECHANICS:
        {
            return {
                items: action.payload
            }
        }
        default:
        {
            return state;
        }
    }
};

export default Mechanics;