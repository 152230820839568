import React from 'react';



// var slideIndex = 1;
// showSlides(slideIndex);

// // Next/previous controls
// function plusSlides(n) {
//   showSlides(slideIndex += n);
// }

// // Thumbnail image controls
// function currentSlide(n) {
//   showSlides(slideIndex = n);
// }

// function showSlides(n) {
//   var i;
//   var slides = document.getElementsByClassName("mySlides");
//   var dots = document.getElementsByClassName("dot");
//   if (n > slides.length) {slideIndex = 1}
//   if (n < 1) {slideIndex = slides.length}
//   for (i = 0; i < slides.length; i++) {
//       slides[i].style.display = "none";
//   }
//   for (i = 0; i < dots.length; i++) {
//       dots[i].className = dots[i].className.replace(" active", "");
//   }
//   slides[slideIndex-1].style.display = "block";
//   dots[slideIndex-1].className += " active";
// }



const Carousel = (props) => {

    const [slideIndex, setSlideIndex] = React.useState(1)
    const {images} = props

    React.useEffect(()=>{
        showSlides(slideIndex)
    }, [])

    const showSlides = (n) => {
        let index = n
        var slides = document.getElementsByClassName("carousel-slide");
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none"
        }
        if (index > images.length) {
            index = 1
            setSlideIndex(1)
        }
        if (index < 1) {
            index = images.length
            setSlideIndex(images.length)
        }
        slides[index-1].style.display = "block";
    }

    const slideDir = (n) => (e) => {
        setSlideIndex(slideIndex + n)
        showSlides(slideIndex + n)
    }

    return (
        <div class="carousel">
            {
                images.map((image, index) => 
                    <div class="carousel-slide fade" key={index}>
                        <img src={image} width="100%" />
                </div>
                )
            } 
          <a class="carousel-prev" onClick={slideDir(-1)}>&#10094;</a>
          <a class="carousel-next" onClick={slideDir(1)}>&#10095;</a>
        </div>
    )
}

export default Carousel