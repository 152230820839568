import { combineReducers } from 'redux';
import Station from './stations';

const drawerReducer = (asyncReducers) =>
    combineReducers({
        station: Station,
      ...asyncReducers
    });

export default drawerReducer();
