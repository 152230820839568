import * as Actions from '../../actions';

const initialState = {
};

const Jobs = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.ADD_JOBS:
        {
            return {
                ...action.payload
            }
        }
        case Actions.ADD_COUNTRY_JOBS:
        {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
        {
            return state;
        }
    }
};

export default Jobs;