import React from 'react';
import './App.css';
import {Router} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import history from './history';
import Routes from './routes';
import Storage from './components/localStorage';
import Header from './components/partials/header';
import Footer from './components/partials/footer';
import Firebase from './components/firebase';
import GoogleMaps from './components/google_maps';
import Comments from './components/comments';
import Sponsors from './components/sponsers';
import DomAdjustments from './components/domAdjustments';
import Alerts from './components/partials/alerts';

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Storage>
          <Firebase>
            <GoogleMaps>
              <Sponsors>
                <div className="App">
                  <header id="Header" className="Header">
                    <Header />
                  </header>
                  <main id="Main" className="Main">
                    <DomAdjustments />
                    <Routes />
                  </main>
                  <Comments />
                  <footer id="Footer" className="Footer">
                    <Footer />
                  </footer>
                </div>
                <Alerts />
              </Sponsors>
            </GoogleMaps>
          </Firebase>
        </Storage>
      </Router>
    </Provider>
  );
}

export default App;
