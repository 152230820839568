import React from 'react';

export default function ColorBox({ color, ...props }) {
    const size = props.size ? props.size : 'md'

    const sizes = {
        sm: {
            outer: 15,
            inner: 10
        },
        md: {
            outer: 24,
            inner: 15
        }
    }

    const style = props.style ? props.style : {}

    return (
        <div style={{
            width: sizes[size].outer,
            height: sizes[size].outer,
            background: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 16,
            ...style
        }}>
            <div style={{ backgroundColor: color, border: '1px solid #fff', width: sizes[size].inner, height: sizes[size].inner }}></div>
        </div>
    )
}