import * as Actions from '../../actions';

const initialState = {
    show: false,
    info: null
};

const Home = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.ADD_EVACUATION:
        {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
        {
            return state;
        }
    }
};

export default Home;