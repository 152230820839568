
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import ArrowBack from '@material-ui/icons/ArrowBack';
import * as Actions from '../store/actions';
import Locations from '../utils/data/locations.json';
import { Divider, List, ListItem } from '@material-ui/core';
import history from '../history.js';
import JobsDrawer from '../components/fragments/drawers/jobs.drawer';
import { Profile } from '../utils/classes/profile';
import Carousel from '../components/fragments/carousel';
import { Page404 } from '../components/fragments/404';


const Jobs = (props) => {
    const {firebase, profiles} = useSelector(state => state)
    const dispatch = useDispatch()
    let COMPANY = new Profile({id: props.match.params.id})
    COMPANY.init()

    React.useEffect(()=>{
        if (props.match && props.match.params.id) {
            dispatch(Actions.getCompanyProfile(props.match.params.id))
        }
    }, [])

    if (!COMPANY.loaded()) {
        return <></>
    }

    if (!COMPANY.exists()) {
        return (
            <Page404 />
        )
    }

    return (
        <div className="page">
            <div style={{position: 'relative', height: '100%'}}>
                <div style={{width: '100%'}}>
                    <div style={{color: "#FFF"}}>
                        <div id="profile-arrow" className="page-content" style={{padding: '12px 8 0px'}}>
                            <ArrowBack onClick={()=>history.goBack()} />
                        </div>
                        <div style={{padding: '0.5rem 1rem'}}>
                            {
                                COMPANY.hasLogo() &&
                                <div style={{paddingBottom: '0.5rem'}}>
                                    <img src={COMPANY.getLogo()} width="100%" />
                                </div>
                            }
                            <h2 style={{margin: 0, padding: 0, paddingBottom: '0.5rem'}}>{COMPANY.getName()}</h2>
                            <div>
                                {COMPANY.getDescription()}
                            </div>
                        </div>
                        <br />
                        {
                            COMPANY.hasImages() &&
                            <div style={{paddingBottom: '2rem'}}>
                                <Carousel images={COMPANY.getImages()} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Jobs;