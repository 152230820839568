import * as Actions from '../../../actions';

const initialState = {
};

const extensions = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.ADD_MAP_SERVICES:
        {
            return {
                ...action.payload
            }
        }
        default:
        {
            return state;
        }
    }
};

export default extensions;