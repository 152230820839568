import * as Actions from '../../actions';

const initialState = {
    description: {
        open: false,
        text: null
    }
};

const Alerts = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.OPEN_DESCRIPTION_ALERT:
        {
            return {
                ...state,
                description: {
                    open: action.payload.open,
                    text: action.payload.text
                }
            }
        }
        default:
        {
            return state;
        }
    }
};

export default Alerts;