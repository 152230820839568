import React from 'react'
import { Close } from '@material-ui/icons'

const sponsors_list = [
    {
        text: 'DMV Hazmat Practice Test',
        link: 'https://dmv-practice-test.com/california/cdl/hazmat-1?gclid=CjwKCAjwkJj6BRA-EiwA0ZVPVg--h4xhtAHcVkAIADG1Bkv2TIRQkhAbmxO1K0OMhfnY4W4lCfHwXxoCvDkQAvD_BwE'
    }
    
]

export default function Sponsors (props) {

    const [show, setShow] = React.useState(false)
    const [sponsor, setSponsor] = React.useState(sponsors_list[0])

    React.useEffect(()=>{
        setTimeout(()=>{
            setShow(true)
            setTimeout(()=>{
                setShow(false)
            }, 15000)
        }, 5000)
    }, [])

    // React.useEffect(()=>{
    //     if (show) {
    //         document.getElementById('Main').style.height = "calc(100vh - 139px)";
    //     } else {
    //         document.getElementById('Main').style.height = "calc(100vh - 105px)";
    //     }
    // }, [show])

    return (
        <React.Fragment>
            {props.children}
            {/* <div id="sponsors" className="sponsors" style={{transform: `translateY(${show ? 0 : 180}px)`}}>
                <div className="sponsors-body">
                    <div className="sponsors-text">
                        <a href={sponsor.link} className="sponsors-link" target="_blank">
                            {sponsor.text}
                        </a>
                    </div>
                    <div style={{color: "#FFF"}} onClick={()=>setShow(false)}>
                        <Close />
                    </div>
                </div>
            </div> */}
        </React.Fragment>
    )

}