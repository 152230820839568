
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as Actions from '../store/actions';
import { Divider, List, ListItem } from '@material-ui/core';
import history from '../history.js';


const Services = (props) => {
    const {firebase} = useSelector(state => state)
    const dispatch = useDispatch()

    const serviceTypes = [
        {text: 'MECHANICS', link: '/mechanics'},
        {text: 'RENTALS', link: '/rentals'},
        {text: 'TIRES', link: '/tires'},
    ]

    return (
        <div className="page">
            <div style={{position: 'relative', height: '100%'}}>
                <div style={{width: '100%'}}>
                    <div style={{color: "#FFF", display: 'flex', flexWrap: 'wrap'}}>
                        <h2 style={{padding: '0 16px'}}>Services</h2>
                        <List style={{width: '100%', padding: 0}}>
                        <Divider />
                        {
                            serviceTypes.map(item =>
                              <React.Fragment>
                                <ListItem style={{padding: '12px 16px'}}>
                                    <div>
                                        <div style={{fontWeight: 'bold'}}>
                                            <span onClick={()=>history.push(item.link)}>{item.text}</span>
                                        </div>
                                    </div>
                                </ListItem>
                                <Divider />
                              </React.Fragment>
                            )
                        }
                        </List>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;