import * as Actions from '../../../actions';

const initialState = {
};

const stations = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.ADD_STATION_MAPS:
        {
            return {
                ...action.payload
            }
        }
        default:
        {
            return state;
        }
    }
};

export default stations;