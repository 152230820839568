
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as Actions from '../store/actions';

const About = (props) => {
    

    return (
        <div className="page">
            <div id="about" className="page-content">
                The RACK App was created to inform drivers of any alerts or issuses regarding equipment, traffic or any infromation pertaining to the facility. We rely on driver input and feedback to update the app on a daily basis. Drivers can comment and post in the form box. We ask for all drivers to be curteous and post acurate information. Thank You for using The RACK App. <br />
                Be safe out there...
                <br /><br />
                Please notify The RACK App of recent repairs or if any alerts need to be removed or updated.
            </div>
        </div>
    )
}

export default About;