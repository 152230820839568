import React from 'react';
import { Button, makeStyles, TextField, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles({
    input: {
        color: '#FFF'
    }
})

const CSSTextField = withStyles({
    root: {
        width: '100%'
    },
})(TextField)

const DoneButton = withStyles({
    root: {
        backgroundColor: "#45B39D",
        color: "#FFF"
    }
})(Button)

const CommentDrawer = ({ ...props }) => {

    const classes = useStyles()

    return (
        <div className="Drawer" style={{display: 'block', minHeight: props.open ? '100%' : 0, backgroundColor: '#2b2d2f'}}>
            <div className="drawer-header">
                <div className="drawer-close" style={{ color: '#fff' }} onClick={props.close}><Close /></div>
                <div className="drawer-title" style={{marginRight: 4}}>Add Comment</div>
            </div>
            <div style={{color: "#FFF", padding: 16}}>
                <CSSTextField
                    value={props.comment}
                    onChange={props.handleCommentChange}
                    multiline
                    rows={4}
                    rowsMax={4}
                    placeholder={'Add your comment here...'}
                    InputProps={{
                        className: classes.input
                    }}
                />
            </div>
            <div style={{display: "flex", justifyContent: "flex-end", padding: "0 16px"}}>
                <DoneButton onClick={props.close} variant={"contained"}>Done</DoneButton>
            </div>
        </div>
    )
}

export default CommentDrawer