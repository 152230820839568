export const TEST = '[TEST]'
export const OPEN_COMMENTS = '[COMMENTS] Open Comments'
export const ADD_COMMENTS = '[COMMENTS] Add Comments'
export const ADD_FIREBASE = '[FIREBASE] Add Firebase';
export const ADD_EVACUATION = '[EVACTUATION] Add Evacuation'; 
export const OPEN_MENU = '[MENU] OPEN MENU';
export const SET_ANNOUNCEMENT = '[Announcement] Set Announcement';
export const SET_LOCATION = '[LOCATION] Set Location';
export const SET_STATE = '[LOCATION] Set State';
export const SET_COUNTRY = '[LOCATION] Set Country';
export const SET_CITY = '[LOCATION] Set City';
export const SET_MAP_TYPE = '[LOCATION] Set Map Type';
export const SET_STATION_DRAWER = '[DRAWER] Set Station Drawer';
export const ADD_MAPS = '[MAPS] Set Maps';
export const ADD_MAP_SERVICES = '[MAPS] Set Map Services';
export const ADD_MEDIA = '[MEDIA] Set Media';
export const ADD_JOBS = '[JOBS] Set Jobs';
export const ADD_COUNTRY_JOBS = '[JOBS] Add Country Jobs';
export const ADD_NEWS = '[NEWS] Set News';
export const ADD_MECHANICS = '[MECHANICS] Set Mechanics';
export const ADD_RENTALS = '[RENTALS] Set Rentals';
export const ADD_TIRES = '[TIRES] Set Tires';
export const ADD_PROFILE = '[PROFILES] Set Profile';
export const ADD_RESOURCE = '[RESOURCE] Add Resource';
export const ADD_STATION_MAPS = '[STATIONS] Add Station Maps';
export const OPEN_DESCRIPTION_ALERT = '[ALERTS] Open Description Alert';

//Comments

export const initialTest = () => {
    return (dispatch) => {
        dispatch({type: TEST, payload: 'Test Success'})
    }
}

export const openComments = (val) => {
    return (dispatch) => {
        dispatch({type: OPEN_COMMENTS, payload: val})
    }
}

export const addComments = (arr) => {
    return (dispatch) => {
        dispatch({type: ADD_COMMENTS, payload: arr})
    }
}

export const addFirebase = (data) => {
    return (dispatch) => {
        dispatch({type: ADD_FIREBASE, payload: data})
    }
}

export const addEvacuation = (data) => {
    return (dispatch) => {
        dispatch({type: ADD_EVACUATION, payload: data})
    }
}

export const openMenu = (val) => {
    return (dispatch) => {
        dispatch({type: OPEN_MENU, payload: val})
    }
}

export const setLocation = (loc) => {
    return (dispatch) => {
        dispatch({type: SET_LOCATION, payload: loc})
    } 
}

export const addMaps = (maps, type) => {
    return (dispatch) => {
        if (!type || type === 'facilities') {
            dispatch({type: ADD_MAPS, payload: maps})
        }

        if (type === 'stations') {
            dispatch({type: ADD_STATION_MAPS, payload: maps})
        }
    }
}

export const addMapServices = (data) => {
    return (dispatch, getState) => {
        dispatch({type: ADD_MAP_SERVICES, payload: data})
    }
}

export const addMedia = (media) => {
    return (dispatch) => {
        dispatch({type: ADD_MEDIA, payload: media})
    }
}

export const addNews = (news) => {
    return (dispatch) => {
        dispatch({type: ADD_NEWS, payload: news})
    }
}

export const addJobs = (jobs) => {
    return (dispatch) => {
        dispatch({type: ADD_JOBS, payload: jobs})
    }
}

export const addMechanics = (mechanics) => {
    return (dispatch) => {
        dispatch({type: ADD_MECHANICS, payload: mechanics})
    }
}

export const addRentals = (rentals) => {
    return (dispatch) => {
        dispatch({type: ADD_RENTALS, payload: rentals})
    }
}

export const addTires = (tires) => {
    return (dispatch) => {
        dispatch({type: ADD_TIRES, payload: tires})
    }
}

export const addMapStations = (stations) => {
    return (dispatch) => {
        dispatch({type: ADD_STATION_MAPS, payload: stations})
    }
}

export const getCompanyProfile = (id, callback) => {
    return (dispatch, getState) => {
        const {profiles, firebase} = getState()

        if (profiles[id]) {
            if (callback) {
                callback()
            }
            return;
        } else {
            firebase.retrieveProfile(id, (data) => {
                if (data !== null) {
                    dispatch({type: ADD_PROFILE, payload: { [id]: data }})
                } else {
                    dispatch({type: ADD_PROFILE, payload: { [id]: null }})
                }
            })
        }
    }
}

export const setAnnouncement = (announcement) => {
    return (dispatch) => dispatch({type: SET_ANNOUNCEMENT, payload: announcement})
}


export const setUserCountryRegion = (country) => {
    return (dispatch) => dispatch({type: SET_COUNTRY, payload: country})
}

export const setUserStateRegion = (state) => {
    return (dispatch) => dispatch({type: SET_STATE, payload: state})
}

export const setUserCityRegion = (city) => {
    return (dispatch) => dispatch({type: SET_CITY, payload: city})
}

export const setMapType = (mapType) => {
    return dispatch => dispatch({type: SET_MAP_TYPE, payload: mapType})
}

export const setStationDrawer = ({show, map, type}) => {
    return dispatch => dispatch({type: SET_STATION_DRAWER, payload: {show, map, type}})
}

export const openDescriptionAlert = (open, txt) => {
    return (dispatch) => dispatch({type: OPEN_DESCRIPTION_ALERT, payload: {open, text: txt} })
}

export const addResource = ({country, state, data}) => {

    return (dispatch, getState) => {
        const {resources} = getState()
        let newResources = {...resources}
        if (!newResources[country]) {
            newResources[country] = {}
        }
        newResources[country][state] = data == null ? [] : data
        dispatch({type: ADD_RESOURCE, payload: newResources})
    }
}

export const addCountryJobs = ({country, state, data}) => {

    return (dispatch, getState) => {
        const {jobs} = getState()
        let newJobs = {...jobs}
        if (!newJobs[country]) {
            newJobs[country] = {}
        }
        newJobs[country][state] = data == null ? [] : data
        dispatch({type: ADD_COUNTRY_JOBS, payload: newJobs})
    }
}