import React from 'react';
import {useDispatch} from 'react-redux';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import MenuIcon from '@material-ui/icons/Menu';
import MenuDrawer from '../fragments/drawers/menu.drawer';
import * as Actions from '../../store/actions';
import history from '../../history';




export default function Header (props) {
    const dispatch = useDispatch()
    
    const openMenu = (e) => {
        dispatch(Actions.openMenu(true))
    }

    return ( 
        <div className="header-bar">
            
            {/* <span  style={{fontWeight: 'bold', fontSize: '1.5rem'}}></span> */}
            <span style={{fontWeight: 'bold'}}><img id='logo' src='/newlogo.png' style={{marginTop: 4}} /></span>
            <div>
                <PlayArrowIcon onClick={()=>history.push('/media')} style={{ marginTop: 8, marginRight: 8 }} />
                <MenuIcon onClick={openMenu} style={{ marginTop: 8 }} />
            </div>
            <MenuDrawer />
        </div>
    )
}