import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { DescriptionAlert } from '../fragments/dialogs';
import * as Actions from '../../store/actions';

export default function Alerts (props) {
    
    const state = useSelector(state => state)
    const dispatch = useDispatch()

    return (
        <>
           <DescriptionAlert open={state.alerts.description.open} close={()=>dispatch(Actions.openDescriptionAlert(false))} description={state.alerts.description.text} />
        </>
    )
}