import React from 'react';
import { List, ListItem } from '@material-ui/core';
import ColorBox from './colorbox';
import { Close } from '@material-ui/icons';


export default function ColorModal({ ...props }) {
    const colorKey = [
        { color: '#45B39D', text: 'Go'},
        { color: '#F1C40F', text: 'Caution'},
        { color: '#C0392B', text: 'Danger'}
    ]

    return (
        <div style={{
            position: "absolute",
            top: 70,
            left: 10,
            zIndex: 1,
            opacity: 0.8,
            backgroundColor: '#000',
            width: 120,
            borderRadius: 4,
            display: props.open ? 'block' : 'none'
        }}>
            <div style={{position: 'relative'}}>
            <div style={{
                margin: 0,
                padding: 0,
                color: "#FFF",
                display: "flex",
                justifyContent: 'flex-end',
                position: 'absolute',
                top: -8,
                left: 8,
                width: "100%"
            }} onClick={props.close}>
                <div style={{
                    backgroundColor: "#000",
                    borderRadius: '50%',
                    border: '1px solid #AAA',
                    width: 23,
                    height: 24
                }} onClick={props.close}>
                    <Close style={{
                        fontSize: '1.2rem',
                        marginTop: 2,
                        marginLeft: 2
                    }} onClick={props.close} />
                </div>
            </div>
            <List>
            {
                colorKey.map(key => 
                    <ListItem>
                        <div style={{ display: 'flex' }}>
                            <div onClick={() => {
                                props.setColor(key.color)
                                props.close()
                            }}>
                                <ColorBox color={key.color} size="sm" style={{marginRight: 8, marginTop: 1}} />
                            </div>
                            <div style={{color: "#FFF", fontSize: 14}}>
                                {key.text}
                            </div>
                        </div>
                    </ListItem>    
                )
                }
            </List>
            </div>
        </div>
    )
}