
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import ArrowBack from '@material-ui/icons/ArrowBack';
import * as Actions from '../store/actions';
import { Divider } from '@material-ui/core';
import history from '../history.js';
import MediaDrawer from '../components/fragments/drawers/media.drawer';


const Media = (props) => {
    const {firebase, media} = useSelector(state => state)
    const dispatch = useDispatch()
    const [link, setLink] = React.useState(null)

    React.useEffect(()=>{
        if (props.match && props.match.params.id) {
            firebase.retrieveVideo(props.match.params.id, (data) => {
                if (data && data.link !== null) {
                    setLink(convertYoutubeLink(data.link))
                }
            })
        }
    }, [])

    
    const close = () => {
        window.history.pushState("", "", '/media');
        setLink(null)
    }
    
    const convertYoutubeLink = (link) => {
        let embedLink = link.replace(/https:\/\/youtu.be\//gi, 'https://www.youtube.com/embed/')
        return embedLink
    }
    
    const setUrl = (item) => {
        window.history.pushState("", "", `/media/${item.id}`);
        setLink(convertYoutubeLink(item.link))
    }

    return (
        <div className="page" style={{overflow: link === null ? undefined : 'hidden'}}>
            <div style={{position: 'relative', height: '100%'}}>
                <div style={{padding: 8}}>
                    <div style={{color: "#FFF", display: 'flex', flexWrap: 'wrap'}}>
                        {
                            media.items.map(item => 
                                <React.Fragment>
                                {
                                    item.image && item.image != null && item.image != '' &&
                                    <div>
                                        <img src={item.image} style={{width: '100%', height: '100%'}} />
                                    </div>
                                }
                                <div style={{padding: 8, paddingBottom: '2rem', width: '100%', wordBreak: 'break-word'}} onClick={()=>setUrl(item)}>
                                    <span style={{fontWeight: 'bold'}}>{item.title}</span>
                                </div>
                                </React.Fragment>
                            )
                        }
                    </div>
                </div>
                {
                    link !== null &&
                    <MediaDrawer link={link} close={close} />
                }
            </div>
        </div>
    )
}

export default Media;