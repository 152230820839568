
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import ArrowBack from '@material-ui/icons/ArrowBack';


const MediaDrawer = (props) => {
    
    const dispatch = useDispatch()
    const {link, close} = props

    return (
        <div style={{backgroundColor: '#000', position: 'absolute', top: 0, width: '100%', height: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'column', height: '86%'}}>
                    <div id="search" className="page-content" style={{padding: '12px 8 0px'}}>
                        <ArrowBack onClick={()=>close()} />
                    </div>
                    <div style={{flexGrow: 1}}>
                    <iframe 
                        width="100%" height="100%" src={`${link}`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                    </div>
            </div>
        </div>
    )
}

export default MediaDrawer;