import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import app from 'firebase/app';
import 'firebase/database';
import * as Actions from '../../store/actions';
import {firebase_config} from '../../keys.json';
import { PhoneCallback } from '@material-ui/icons';
import MAPSERVICES from '../../utils/data/map_services.json'
import { Redirect } from 'react-router';


app.initializeApp(firebase_config);

export default function Firebase (props) {

    const {location, maps} = useSelector(state => state);
    const dispatch = useDispatch()
    
    const gotData = (data) => {
        var messages = data.val();
        if (messages === null) {
            dispatch(Actions.addComments({}))
        } else {
            dispatch(Actions.addComments(messages))
        }
    }
    
    const getMaps = (type = 'facilities') => (data) => {
        let maps = data.val();
        if (maps === null) {
            dispatch(Actions.addMaps({}))
        } else {
            dispatch(Actions.addMaps(maps, type))
        }
    }
    
    const getMedia = (data) => {
        let media = data.val()
        if (media !== null) {
            let arr = Object.keys(media).map(key => { return {id: key.replace(/-/g, ''), ...media[key]}} ).reverse()
            dispatch(Actions.addMedia(arr))
        }
    }

    const getFBdata = (data, type) => {
        let arr;
        let d = data.val()
        if (d == null && type !== 'map_services') {
            return;
        }
        switch (type) {
            case 'jobs':
                let obj = {}
                Object.keys(d).map(key => { 
                    let ky = key.slice(1)
                    obj[ky] = {id: ky, ...d[key]}
                    //return {id: key.replace(/-/g, ''), ...d[key]}
                } )
                dispatch(Actions.addJobs(obj))
                break;
            case 'media':
                arr = Object.keys(d).map(key => { return {id: key.slice(1), ...d[key]}} ).reverse()
                dispatch(Actions.addMedia(arr))
                break;
            case 'news':
                arr = Object.keys(d).map(key => { return {id: key.slice(1), ...d[key]}} )
                dispatch(Actions.addNews(arr))
                break;
            case 'mechanics':
                arr = Object.keys(d).map(key => { return {id: key.slice(1), ...d[key]}} )
                dispatch(Actions.addMechanics(arr))
                break;
            case 'rentals':
                arr = Object.keys(d).map(key => { return {id: key.slice(1), ...d[key]}} )
                dispatch(Actions.addRentals(arr))
                break;
            case 'tires':
                arr = Object.keys(d).map(key => { return {id: key.slice(1), ...d[key]}} )
                dispatch(Actions.addTires(arr))
                break; 
            case 'map_services':
                dispatch(Actions.addMapServices(data.val()))
                break;
            default:
                break;
        }
    }

    React.useEffect(()=>{
        //app.initializeApp(firebase_config);
        var database = app.database();
        var ref = database.ref('messages/' + location.map);
        ref.on('value', gotData, ()=>{});
        //Map Data
        let mapRef = database.ref('/country_locations');
        mapRef.on('value', getMaps('facilities'), () => { })
        //Station Map Data
        let stationMapRef = database.ref('/country_stations');
        stationMapRef.on('value', getMaps('stations'), () => {})
        //Announcement Data
        let announcementRef = database.ref('/announcement');
        announcementRef.on('value', (data)=>dispatch(Actions.setAnnouncement(data.val())), ()=>{})
        //Jobs Data
        // let jobRef = database.ref('/jobs');
        // jobRef.on('value', (data)=>getFBdata(data, 'jobs'), ()=>{})
        //Media Data
        let mediaRef = database.ref('/media');
        mediaRef.on('value', (data)=>getFBdata(data, 'media'), ()=>{})
        //News Data
        let newsRef = database.ref('/news');
        newsRef.on('value', (data) => getFBdata(data, 'news'), () => { })
        
        /** NOTE: Services not in use */
        // //Mechanic Data
        // let mechanicRef = database.ref('/mechanics');
        // mechanicRef.on('value', (data)=>getFBdata(data, 'mechanics'), ()=>{})
        // //Rentals Data
        // let rentalsRef = database.ref('/rentals');
        // rentalsRef.on('value', (data)=>getFBdata(data, 'rentals'), ()=>{})
        // //Tires Data
        // let tiresRef = database.ref('/tires');
        // tiresRef.on('value', (data) => getFBdata(data, 'tires'), () => { })
        
        //Map Services Data
        // if (process.env.NODE_ENV === 'development') {
        //     dispatch(Actions.addMapServices(MAPSERVICES))
        // } else {
            let mapExtRef = database.ref('/map_services')
            mapExtRef.on('value', (data)=>getFBdata(data, 'map_services', ()=>{}))    
        //}

        //Firebase data retrieve functions
        dispatch(Actions.addFirebase({
            retrieveLocationExtensions: (id, cb) => {
                let itemRef = database.ref('/map_ext/' + id)
                itemRef.on('value', (data) => {
                    cb(data.val())
                })
            },
            retrieveProfile: (id, cb) => {
                let itemRef = database.ref('/profiles/' + id)
                itemRef.on('value', (data) => {
                    cb(data.val())
                })
            },
            retrieveVideo: (id, cb) => {
                let itemRef = database.ref('/media/' + '-' + id)
                itemRef.on('value', 
                (data)=>{
                    cb(data.val())
                }, ()=>{})
            },
            retrieveResource: (data, cb) => {
                let itemRef = database.ref('/resources/' + data.country + '/' + data.state)
                itemRef.on('value', 
                (fbd)=>{
                    let d = fbd.val() == null ? [] : fbd.val()
                    dispatch(Actions.addResource({country: data.country, state: data.state, data: d}))
                    cb(d)
                }, ()=>{})
            },
            retrieveJobs: (data, cb) => {
                let itemRef = database.ref('/country_jobs/' + data.country + '/' + data.state)
                itemRef.on('value', 
                (fbd)=>{
                    let d = fbd.val() == null ? {} : fbd.val()
                    dispatch(Actions.addCountryJobs({country: data.country, state: data.state, data: d}))
                    cb(d)
                }, ()=>{})
            },
            submit: (data) => {
                var newMessageRef = ref.push();
                newMessageRef.set(data);
            }
        }))

    }, [])

    React.useEffect(()=> {
        var database = app.database();
        var ref = database.ref('messages/' + location.map);
        ref.on('value', gotData, ()=>{});
        dispatch(Actions.addFirebase({
            submit: (data) => {
                var newMessageRef = ref.push();
                newMessageRef.set(data);
            },
            submitFacilityRequest: (data, cb) => {
                let ref = database.ref('requests')
                ref.push(data, (error) => {
                    if (error) {
                        return;
                    }
                    cb()
                })
            }
        }))
    }, [location.map])


    if (Object.keys(maps).length === 0) {
        return <></>
    } 

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}