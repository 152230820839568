import * as Actions from '../../actions';

const initialState = {
    show: false,
    dataset: {}
};

const Home = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.OPEN_COMMENTS:
        {
            return {
                ...state,
                show: action.payload
            }
        }
        case Actions.ADD_COMMENTS:
        {
            return {
                ...state,
                dataset: action.payload
            }
        }
        default:
        {
            return state;
        }
    }
};

export default Home;