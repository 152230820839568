import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Build, Info, InfoOutlined, LocalShipping, LocationCity } from '@material-ui/icons';
import { WelcomeAlert, MapSelectDialog } from '../components/fragments/dialogs';
import FacilitySelectDrawer from '../components/fragments/drawers/facilitySelect.drawer';
import Logo from '../newlogo.png'
import { Redirect } from 'react-router';

const SelectMap = (props) => {

    const [open, setOpen] = React.useState(false)
    const [mapSelectOpen, setMapSelectOpen] = React.useState(false)

    const close = () => setOpen(false)
    const mapSelectClose = () => setMapSelectOpen(false)

    React.useEffect(()=>{

        if (localStorage.getItem('is_returning') === null && sessionStorage.getItem('wd') === null) {
            sessionStorage.setItem('wd', true)
            setOpen(true)
        }

    }, [])

    if (localStorage.getItem('is_returning')) {
        return <Redirect to={'/'} />
    }

    return (
        <div className="page">
            <div id="map" className="map" style={{color: "#FFF"}}>
                <div style={{display: 'grid', placeItems: 'center', height: '100%', marginTop: '-50px'}}>
                    <div style={{width:'100%', height: 192, marginTop: '12px'}}>
                        <div style={{width: 192, margin: '0 auto'}} onClick={()=>{setMapSelectOpen(true)}}>
                            <img src={Logo} style={{width: '100%', height: "100%"}} />
                        </div>
                        <div style={{width: '80%', margin: '12px auto 0', textAlign: 'center'}} onClick={()=>{setMapSelectOpen(true)}}>
                            <span>Tap here to select your region and facility</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <WelcomeAlert open={open} close={close} /> */}
            <FacilitySelectDrawer open={mapSelectOpen} close={()=>setMapSelectOpen(false)} />
        </div>
    )
}

export default SelectMap;