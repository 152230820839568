
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import ArrowBack from '@material-ui/icons/ArrowBack';
import * as Actions from '../store/actions';
import { Divider, List, ListItem } from '@material-ui/core';
import history from '../history.js';
import JobsDrawer from '../components/fragments/drawers/jobs.drawer';
import { CountryStateDropdown } from '../components/fragments/dropdowns';


const Jobs = (props) => {
    const [country, setCountry] = React.useState('US')
    const [state, setState] = React.useState('CA')
    const {location, firebase, jobs} = useSelector(state => state)
    const dispatch = useDispatch()
    const [load, setLoad] = React.useState(true);
    //let job_ids = Object.keys(jobs)
    //let items = jobs
    const [id, setId] = React.useState(null) 

    React.useEffect(()=>{
        if (props.match && props.match.params.country && props.match.params.state && props.match.params.id) {
            let {country, state, id} = props.match.params
            firebase.retrieveJobs({country: country, state: state}, ()=>{
                setCountry(country)
                setState(state)
                setId(id)
                setLoad(false)
            })
            //setId(props.match.params.id)
        } else {
            setCountry(location.country)
            setState(location.state)
            findJobs(location.country, location.state)
            setLoad(false)
        }
    }, [])

    const close = () => {
        window.history.pushState("", "", '/jobs');
        setId(null)
    }

    const openJobId = (id) => {
        window.history.pushState("", "", `/jobs/${country}/${state}/${id}`);
        setId(id)
    }

    const findJobs = (country, state) => {
        if (!jobs[country] || !jobs[country][state]) {
            firebase.retrieveJobs({country: country, state: state}, ()=>{})
        }
    }

    const handleChange = (type) => (e) => {
        if (type === 'country') {
            setCountry(e.target.value)
            setState('null')
        }
        if (type === 'state') {
            setState(e.target.value)
            findJobs(country, e.target.value)
        }
    }

    const resetRegion = () => {
        setCountry(location.country)
        setState(location.state)
        findJobs(location.country, location.state)
    }

    if (load) {
        return <></>
    }

    return (
        <div className="page">
            <div style={{position: 'relative', height: '100%'}}>
                <div style={{width: '100%'}}>
                    <div style={{color: "#FFF", display: 'flex', flexWrap: 'wrap'}}>
                        <div style={{padding: '24px 16px 8px', width: '100%'}}>
                            <CountryStateDropdown country={country} state={state} onChange={handleChange} />
                        </div>
                        {
                            jobs[country] && jobs[country][state] && Object.keys(jobs[country][state]).length > 0 ?
                            <List style={{width: '100%', borderTop: '1px solid #2b2d2f', display: id !== null ? 'none': undefined}}>
                                {
                                    Object.keys(jobs[country][state]).map(key => {
                                        let job = jobs[country][state][key]
                                        return (
                                            <React.Fragment key={key}>
                                                <ListItem>
                                                    <div>
                                                        <div style={{fontWeight: 'bold'}}>
                                                            <span onClick={()=>openJobId(key)}>{job.company}</span>
                                                        </div>
                                                        <ul>
                                                            <li>Location: {job.location}</li>
                                                            <li>
                                                                Minimum Age: {job.age}
                                                            </li>                          
                                                            <li>Driver Types: {job.driver_types.join(', ')}</li>
                                                            {
                                                                job.endorsements.length > 0 &&
                                                                <li>Endorsements: {job.endorsements.join(', ')}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </ListItem>
                                                <Divider />
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </List>
                            :
                            <div style={{width: '100%', borderTop: '1px solid #2b2d2f', padding: '1rem'}}>
                                There are currently no jobs in this area.
                            </div>
                        }
                    </div>
                </div>
            {id != null &&
                <JobsDrawer country={country} state={state} id={id} close={close} resetRegion={resetRegion} />
            }
            </div>
        </div>
    )
}

export default Jobs;