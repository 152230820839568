import React from 'react';
import { Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';

const CitiesList = ({children, cities, currCity, setCurrCity, ...props}) => {
    const citiesArr = cities ? Object.keys(cities).sort() : []

    if (citiesArr.length === 0 || props.useEmpty) {
        return (
            <p style={{textAlign: 'center', padding: 12, fontSize: '1.2rem'}}>
                There are currently no stations for this state. To make a request, <Link to="/requests/station" style={{color: "#FFF"}}>tap here</Link>
            </p>
        )
    }

    return (
        <ul className="list-default">
            {
                citiesArr.map(city => 
                    <React.Fragment key={city}>
                        <li
                            style={{
                                padding: 8,
                                paddingLeft: 24,
                                cursor: 'pointer',
                                backgroundColor: city === currCity ? '#222' : undefined,
                                fontWeight: city === currCity ? 'bold' : undefined
                            }}
                            onClick={() => {
                                if (city === currCity) {
                                    setCurrCity(null)
                                    return;
                                }
                                setCurrCity(city)
                            }}
                            >
                            {city.replace(/_/g, ' ')}
                        </li>
                        <Divider />
                        {
                            city === currCity ? children : <></>
                        }
                    </React.Fragment>
                
                )
            }
        </ul>
    )
}

export default CitiesList;