
import React from 'react';
import { Link } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import ArrowBack from '@material-ui/icons/ArrowBack';


const JobsDrawer = (props) => {
    const {jobs} = useSelector(state => state)
    const dispatch = useDispatch()
    const {country, state, id, close} = props
    let item = jobs[country][state][id]

    React.useEffect(()=>{
        if (!item) {
            props.resetRegion()
            close()
        }
    }, [])

    if (!item) {
        return <></>
    }

    return (
        <div style={{backgroundColor: '#000', position: 'absolute', top: 0, width: '100%', height: '100%', overflow: 'scroll'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div id="search" className="page-content" style={{padding: '12px 8 0px'}}>
                        <ArrowBack onClick={()=>close()} />
                    </div>
                    <div style={{color: '#FFF', flexGrow: 1, padding: '0 8px'}}>
                        <h2 style={{margin: 0, padding: '0 2px'}}>{item.company}</h2>
                        {
                            item.profile &&
                            <div style={{margin: '12px 0'}}>
                                <Link to={'/' + item.profile} style={{color:"#FFF", fontSize: 20, fontWeight: 'bold'}}>View Profile</Link>
                            </div>
                        }
                        {
                            item.company_description && item.company_description != "null" &&
                            <div><span style={{fontWeight: 'bold'}}>Description:</span> {item.company_description}</div>
                        }
                        <ul style={{paddingBottom: '2rem'}}>
                            <li><span style={{fontWeight: 'bold'}}>Location:</span> {item.location}</li>
                            <li><span style={{fontWeight: 'bold'}}>Age Requirement:</span> {item.age}+</li>
                            {
                                item.background_check && item.background_check != "null" &&
                                <li><span style={{fontWeight: 'bold'}}>Background Check:</span> {item.background_check ? 'Yes' : 'No'}</li>
                            }
                            {
                                item.drug_testing && item.drug_testing != "null" &&
                                <li><span style={{fontWeight: 'bold'}}>Drug Testing:</span> {item.drug_testing ? 'Yes' : 'No'}</li>
                            }
                            {
                                item.benefits && item.benefits.length > 0 &&
                                <li><span style={{fontWeight: 'bold'}}>Benefits:</span>
                                    <ul>
                                        {item.benefits.map(b => 
                                            <li>{b}</li>
                                        )}
                                    </ul>
                                </li>
                            }
                            <li><span style={{fontWeight: 'bold'}}>Driver Types: </span>
                            <br/> {item.driver_types.join(', ')}</li>
                            {
                                item.endorsements && item.endorsements.length > 0 &&
                                <li><span style={{fontWeight: 'bold'}}>Endorsements:</span>
                                <br/> {item.endorsements.join(', ')}</li>
                            }
                            <li><span style={{fontWeight: 'bold'}}>Qualifications: </span>
                                <ul style={{margin: 0, padding: 0}}>
                                {Object.keys(item.qualifications).map(iq => 
                                    <li style={{listStyleType: 'none'}}>
                                        {iq != 'default' && <span>{iq.charAt(0).toUpperCase() + iq.slice(1)}</span>}
                                        <ul>
                                            {item.qualifications[iq].map(q => 
                                                <li>{q}</li>
                                            )}
                                        </ul>
                                    </li>
                                )}
                                </ul>
                            </li>
                            {
                                item.phone && item.phone != "null" &&
                                <li><span style={{fontWeight: 'bold'}}>Phone</span>
                                <br/>{item.phone}</li>
                            }
                            {
                                item.fax && item.fax != "null" &&
                                <li><span style={{fontWeight: 'bold'}}>Fax</span>
                                <br/>{item.fax}</li>
                            }
                            {
                                item.email && item.email != "null" &&
                                <li><span style={{fontWeight: 'bold'}}>Email</span>
                                <br/>{item.email}</li>
                            }
                            {
                                item.mail && item.mail != "null" &&
                                <li><span style={{fontWeight: 'bold'}}>Mail</span>
                                <br/>{item.mail}</li>
                            }
                            {
                                item.address && item.address != "null" &&
                                <li><span style={{fontWeight: 'bold'}}>Address</span>
                                <br/>{item.address}</li>
                            }
                            {
                                item.contact_preference && item.contact_preference.length > 0 &&
                                <li><span style={{fontWeight: 'bold'}}>Contact Preference</span>
                                <br/>{item.contact_preference.join(', ')}</li>
                            }
                            {
                                item.online_app && item.online_app != "null" &&
                                <li><span style={{fontWeight: 'bold'}}>Online App</span>
                                <br/><a href={item.online_app} target="_blank" style={{color: 'green', textDecoration: 'no-underline'}}>Available</a></li>
                            }
                            {
                                item.printable_app && item.printable_app != "null" &&
                                <li><span style={{fontWeight: 'bold'}}>Printable App</span>
                                <br/><a href={item.printable_app} target="_blank" style={{color: 'green', textDecoration: 'no-underline'}}>Available</a></li>
                            }
                        </ul>
                    </div>
            </div>
        </div>
    )
}

export default JobsDrawer;