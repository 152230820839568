import React from 'react';
import { Publish, DeleteForever, Menu, Palette } from '@material-ui/icons';
import AddCommentIcon from '@material-ui/icons/AddComment'
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@material-ui/core';
import CommentDrawer from './comment_drawer';
import ColorModal from './color_modal';
import ColorBox from './colorbox';

// const ColorBox = ({ color, ...props }) => {
//     return (
//         <div style={{
//             width: 24,
//             height: 24,
//             background: 'transparent',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             marginRight: 16
//         }}>
//             <div style={{ backgroundColor: color, border: '1px solid #fff', width: 15, height: 15 }}></div>
//         </div>
//     )
// }

const ToolsMenu = ({...props}) => {

    const [open, setOpen] = React.useState(false);
    const [comment, setComment] = React.useState('')
    const [openCommentDialog, setOpenCommentDialog] = React.useState(false)
    const [openColorModal, setOpenColorModal] = React.useState(false)
    const [currentColor, setCurrentColor] = React.useState(props.context.strokeStyle)
    const context = props.context

    // const close = () => {
    //     setOpen(false)
    // }

    let emailSubject = encodeURI('New Station Map')
    let emailBody = `Route for location:\n${props.address}\n\ncomment: \n${comment}`
    let emailHref = `mailto: rackdevops@gmail.com?subject=${emailSubject}&body=${encodeURI(emailBody)}`

    // const items = [
    //     {
    //         text: 'Colors',
    //         icon: <Palette />,
    //         onClick: () => {
    //             setOpenColorModal(true)
    //         }
    //     },
    //     {
    //         text: 'Add Comments',
    //         icon: <ChatBubble />,
    //         onClick: () => {
    //             setOpenCommentDialog(true)
    //         },
    //         color: comment !== '' ? "#45B39D" : "#FFF"
    //     },
    //     {
    //         text: 'Clear',
    //         icon: <DeleteForever />,
    //         onClick: () => {
    //             context.clearRect(0, 0, window.innerWidth, window.innerHeight)
    //         },
    //         color: "#C0392B"
    //     },
    //     {
    //         text: 'Upload',
    //         icon: <Publish />,
    //         onClick: () => {
    //             window.open(`mailto: huynha859@gmail.com?subject=${encodeURI('New Station Map')}&body=${encodeURI(emailBody)}`)
    //         }
    //     }
    // ]

    return (
        <React.Fragment>
            <div style={{ display: 'flex' }}>
                <div onClick={()=>setOpenColorModal(true)}>
                    <ColorBox color={currentColor} style={{marginRight: 16, marginTop: -2}} />
                </div>
                <div onClick={()=>setOpenCommentDialog(true)} style={{marginRight: 16}}>
                    <AddCommentIcon style={{color: comment === '' ? "#FFF" : "#45B39D", fontSize: '1.4rem'}} />
                </div>
                <div onClick={() => {
                    context.clearRect(0, 0, window.innerWidth, window.innerHeight)
                }} style={{marginRight: 16, marginTop: -1.5, color: '#C0392B'}}>
                    <DeleteForever />
                </div>
                <div
                    onClick={() => {
                        window.open(emailHref)
                    }}
                    style={{ color: "#FFF", marginRight: 8, marginTop: -1 }}>
                    <Publish />
                </div>
                {/* <Menu onClick={() => setOpen(!open)} style={{ cursor: "pointer", color: "#FFF" }} /> */}
            </div>
            {/* <Drawer anchor={"right"} open={open} onClose={close}>
                <List style={{minWidth: 150, color: "#FFF"}}>
                    {
                        items.map(item => 
                            <ListItem button onClick={() => {
                                item.onClick()
                                close()
                            }}>
                                <ListItemIcon style={{color: item.color ? item.color : "#FFF"}}>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItem>
                        )    
                    }
                </List>
            </Drawer> */}
            <CommentDrawer
                open={openCommentDialog}
                close={() => {
                    setOpenCommentDialog(false)
                }}
                comment={comment}
                handleCommentChange={(e)=>setComment(e.target.value)}
            />
            <ColorModal
                open={openColorModal}
                close={() => setOpenColorModal(false)}
                setColor={(color) => {
                    setCurrentColor(color)
                    context.strokeStyle = color
                }}
            />
        </React.Fragment>
    )
}

export default ToolsMenu