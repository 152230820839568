import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as Actions from '../../store/actions';

const LocalStorage = (props) => {

    const dispatch = useDispatch()

    React.useEffect(()=>{

        let lastMap = localStorage.getItem('l_m')

        if (lastMap && lastMap !== null && lastMap !== '') {
            lastMap = JSON.parse(lastMap)
            if (lastMap.country && lastMap.state && lastMap.map && lastMap.map_type) {
                //dispatch country
                dispatch(Actions.setUserCountryRegion(lastMap.country))
                dispatch(Actions.setUserStateRegion(lastMap.state))
                dispatch(Actions.setLocation(lastMap.map))
                dispatch(Actions.setMapType(lastMap.map_type))
                dispatch(Actions.setUserCityRegion(lastMap.city || null))
                if (!lastMap.city) {
                    localStorage.setItem('l_m', JSON.stringify({...lastMap, city: null}))
                }
            } else {
                localStorage.setItem('l_m', JSON.stringify({country: "US", state: "CA", city: null, map: "Kinder_Morgan_Colton_Terminal", map_type: "facility"}))
            }
        } else {
            localStorage.setItem('l_m', JSON.stringify({country: "US", state: "CA", city: null, map: "Kinder_Morgan_Colton_Terminal", map_type: "facility"}))
        }

    }, [])
    
    return (
        <>
            {props.children}
        </>
    )
}

export default LocalStorage