let countryKeys = {
    "CA": "Canada",
    "US": "United States"
}

let stateKeys = {
    CA: {
        //Canada countries here...
        BC: "British Columbia",
        AB: "Alberta",
        SK: "Saskatchewan",
        MB: "Manitoba",
        ON: "Ontario",
        QC: "Quebec",
        NL: "Newfoundland and Labrador",
        NB: "New Brunswick",
        PE: "Prince Edward Island",
        NS: "Nova Scotia",
        YT: "Yukon",
        NT: "Northwest Territories",
        NU: "Nunavut"        
    },
    US: {
        AL: 'Alabama',
        AK: 'Alaska',
        AZ: 'Arizona',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: 'Delaware',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
            OH: 'Ohio',
            OK: 'Oklahoma',
            OR: 'Oregon',
            PA: 'Pennsylvania',
            RI: 'Rhode Island',
            SC: 'South Carolina',
            SD: 'South Dakota',
            TN: 'Tennessee',
            TX: 'Texas',
            UT: 'Utah',
            VT: 'Vermont',
            VA: 'Virginia',
            WA: 'Washington',
            WV: 'West Virginia',
            WI: 'Wisconsin',
            WY: 'Wyoming'
    }
}

export const getCountryName = (country) => {
    return countryKeys[country]
}

export const getStateName = (country, state) => {
    return stateKeys[country][state]
}
export const getCountryList = () => {
    return countryKeys
}

export const getStateList = (country) => {
    return stateKeys[country]
}